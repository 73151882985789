import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BasicPropertyTable from "./BasicPropertyTable";
import LifeStyleTable from "./LifeStyleTable";
import BrandTable from "./BrandTable";
import KeywordMap from "./KeywordMap";
import "./App.css";
import Modal from "react-modal";
import MultivariateAnalysis from "./MultivariateAnalysis";
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PositioningMap from "./PositioningMap";
import { getDS } from "./DataStore";
import Api from "./Api";
import {formatSurveyYear, formatSurvey, formatSs} from "./common";
import Toast from 'react-bootstrap/Toast';

Modal.setAppElement("#root");

/**
 * ペルソナ詳細ページ描画クラス
 */
class PersonaDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestBasic: "",
      requestLifestyle: "",
      requestBrand: "",
      requestPersona: "",
      responseBasic: "",
      responseLifestyle: "",
      responseBrand: "",
      responsePersona: "",
      responseFreeform: "",
      personaId: 1,
      axisId: 1,
      nodeList: "",
      linkList: "",
      pmapPersona: [],
      labelImage: "",
      graphData: "",
      prefImage: "",
      title: "",
      brandDisplayList: "",
      brandPrevDisplayList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      lifestyleDisplayList: "",
      lifestylePrevDisplayList: [4, 5, 6, 20, 21, 0, 1, 2, 3, 7, 8, 9, 10, 11, 12],
      id: "",
    };
    this.setRequestBasic = this.setRequestBasic.bind(this);
    this.setRequestLifestyle = this.setRequestLifestyle.bind(this);
    this.setRequestBrand = this.setRequestBrand.bind(this);
    this.kwMapAjax = this.kwMapAjax.bind(this);
    this.pmapPersonaAjax = this.pmapPersonaAjax.bind(this);
    this.setImage = this.setImage.bind(this);
    this.requestAjax = this.requestAjax.bind(this);
    this.personaDetailGenerator = this.personaDetailGenerator.bind(this);
    this.prefAjax = this.prefAjax.bind(this);
    this.setGraph = this.setGraph.bind(this);
    this.setThumbnail = this.setThumbnail.bind(this);
    this.setDisplayBrand = this.setDisplayBrand.bind(this);
    this.setPrevDisplayBrand = this.setPrevDisplayBrand.bind(this);
    this.setDisplayLifestyle = this.setDisplayLifestyle.bind(this);
    this.setPrevDisplayLifestyle = this.setPrevDisplayLifestyle.bind(this);
  }

  /**
   * 基本属性で絞り込みが行われた時、リクエストをその絞り込み条件に更新する
   * @param {Array} basic_list - 基本属性の絞り込み条件
   * @param {Array} lifestyle_list - ライフスタイルの絞り込み条件
   * @param {Array} purchasing_behavior_list - 購買行動の絞り込み条件
   * @param {Array} persona_list - ペルソナスケルトンの絞り込み条件
   * @param {object} filterData - filterAPIのリクエスト
   */
  setRequestBasic(basic_list) {
    this.setState({ requestBasic: basic_list });
    let lifestyle_list = this.state.requestLifestyle;
    let purchasing_behavior_list = this.state.requestBrand;
    let persona_list = this.state.requestPersona;
    let switch_lifestyle_list = this.state.lifestyleDisplayList;
    let switch_purchasing_behavior_list = this.state.brandDisplayList;
    let filterData = {
      basic_list,
      lifestyle_list,
      persona_list,
      purchasing_behavior_list,
      switch_lifestyle_list,
      switch_purchasing_behavior_list,
      "switch_persona_detail": true,
      "switch_survey": formatSurvey()
    };
    this.setState({ graphData: filterData });
    this.requestAjax.bind(this, filterData)();
    this.kwMapAjax.bind(this, filterData)();
    this.pmapPersonaAjax.bind(this)();
    this.prefAjax.bind(this, filterData)();
  }

  /**
   * ライフスタイルで絞り込みが行われた時、リクエストをその絞り込み条件に更新する
   * @param {Array} basic_list - 基本属性の絞り込み条件
   * @param {Array} lifestyle_list - ライフスタイルの絞り込み条件
   * @param {Array} purchasing_behavior_list - 購買行動の絞り込み条件
   * @param {Array} persona_list - ペルソナスケルトンの絞り込み条件
   * @param {object} filterData - filterAPIのリクエスト
   */
  setRequestLifestyle(lifestyle_list) {
    this.setState({ requestLifestyle: lifestyle_list });
    let basic_list = this.state.requestBasic;
    let purchasing_behavior_list = this.state.requestBrand;
    let persona_list = this.state.requestPersona;
    let switch_lifestyle_list = this.state.lifestyleDisplayList;
    let switch_purchasing_behavior_list = this.state.brandDisplayList;
    let filterData = {
      basic_list,
      lifestyle_list,
      persona_list,
      purchasing_behavior_list,
      switch_lifestyle_list,
      switch_purchasing_behavior_list,
      "switch_persona_detail": true,
      "switch_survey": formatSurvey()
    };
    this.setState({ graphData: filterData });
    this.requestAjax.bind(this, filterData)();
    this.kwMapAjax.bind(this, filterData)();
    this.pmapPersonaAjax.bind(this)();
    this.prefAjax.bind(this, filterData)();
  }

  /**
   * 購買行動で絞り込みが行われた時、リクエストをその絞り込み条件に更新する
   * @param {Array} basic_list - 基本属性の絞り込み条件
   * @param {Array} lifestyle_list - ライフスタイルの絞り込み条件
   * @param {Array} purchasing_behavior_list - 購買行動の絞り込み条件
   * @param {Array} persona_list - ペルソナスケルトンの絞り込み条件
   * @param {object} filterData - filterAPIのリクエスト
   */
  setRequestBrand(purchasing_behavior_list) {
    this.setState({ requestBrand: purchasing_behavior_list });
    let lifestyle_list = this.state.requestLifestyle;
    let basic_list = this.state.requestBasic;
    let persona_list = this.state.requestPersona;
    let switch_lifestyle_list = this.state.lifestyleDisplayList;
    let switch_purchasing_behavior_list = this.state.brandDisplayList;
    let filterData = {
      basic_list,
      lifestyle_list,
      persona_list,
      purchasing_behavior_list,
      switch_lifestyle_list,
      switch_purchasing_behavior_list,
      "switch_persona_detail": true,
      "switch_survey": formatSurvey()
    };
    this.setState({ graphData: filterData });
    this.requestAjax.bind(this, filterData)();
    this.kwMapAjax.bind(this, filterData)();
    this.pmapPersonaAjax.bind(this)();
    this.prefAjax.bind(this, filterData)();
  }

  /**
   * 購買行動テーブルで表示切替が行われた時、画面表示用リストを更新する
   * @param {Array} displayList - 購買行動5ブロック画面表示用idリスト
   */
  setDisplayBrand(displayList) {
    let basic_list = this.state.requestBasic;
    let purchasing_behavior_list = this.state.requestBrand;
    let lifestyle_list = this.state.requestLifestyle;
    let persona_list = this.state.requestPersona;
    let switch_lifestyle_list = this.state.lifestyleDisplayList;
    let switch_purchasing_behavior_list = displayList;
    let switch_persona_list = this.state.personaDisplayList;
    let filterData = {
      basic_list,
      lifestyle_list,
      persona_list,
      purchasing_behavior_list,
      switch_lifestyle_list,
      switch_purchasing_behavior_list,
      switch_persona_list,
      "switch_persona_detail": true,
      "switch_survey": formatSurvey()
    };
    this.setState({ 
      brandDisplayList: displayList,
      filterJson: filterData
     });
     this.props.setPersonaFilterState(JSON.parse(JSON.stringify(filterData)));
  }

  /**
   * 購買行動テーブルで表示切替が行われた時、直前の画面表示状態をリストに保存する
   * @param {Array} displayPrevList - 表示切替直前まで画面表示されていた5ブロックのidリスト
   */
  setPrevDisplayBrand(displayPrevList) {
    this.setState({ brandPrevDisplayList: displayPrevList });
  }

  /**
   * ライフスタイルテーブルで表示切替が行われた時、画面表示用リストを更新する
   * @param {Array} displayList - ライフスタイルブロック画面表示用idリスト
   */
  setDisplayLifestyle(displayList) {
    let basic_list = this.state.requestBasic;
    let purchasing_behavior_list = this.state.requestBrand;
    let lifestyle_list = this.state.requestLifestyle;
    let persona_list = this.state.requestPersona;
    let switch_lifestyle_list = displayList;
    let switch_purchasing_behavior_list = this.state.brandDisplayList;
    let switch_persona_list = this.state.personaDisplayList;
    let filterData = {
      basic_list,
      lifestyle_list,
      persona_list,
      purchasing_behavior_list,
      switch_lifestyle_list,
      switch_purchasing_behavior_list,
      switch_persona_list,
      "switch_persona_detail": true,
      "switch_survey": formatSurvey()
    };
    this.setState({ 
      lifestyleDisplayList: displayList,
      filterJson: filterData
     });
    this.props.setPersonaFilterState(JSON.parse(JSON.stringify(filterData)));
  }

  /**
   * ライフスタイルテーブルで表示切替が行われた時、直前の画面表示状態をリストに保存する
   * @param {Array} displayPrevList - 表示切替直前まで画面表示されていたライフスタイルブロックのidリスト
   */
  setPrevDisplayLifestyle(displayPrevList) {
    this.setState({ lifestylePrevDisplayList: displayPrevList });
  }

  /**
   * (React組み込み関数)初期画面表示のためのデータを整理する
   * ※Top.jsにUNSAFE_componentWillMount()の公式URLを記載
   * @param {string} hash - URLの#以降を切り取りデコードした文字列
   * @param {string} get - hashから絞り込み条件のみを切り取った文字列
   * @param {string} personaId - URLから切り取ったペルソナスケルトンid
   * @param {string} axisId - URLから切り取ったペルソナ軸id
   * @param {string} jwt - 認証情報
   * @param {JSON} getFilter - JSON化した絞り込み条件
   * @param {Array} pmap - ポジショニングマップサムネイルのリスト
   */
  UNSAFE_componentWillMount() {
    let hash = decodeURIComponent(window.location.hash);
    let self = this;
    let get = hash.split("&filter=")[1];
    let personaId = hash.split("&persona=")[1].split("&filter=")[0];
    let axisId = hash.split("axis=")[1].split("&persona=")[0];
    this.setState({
      personaId: personaId - 1,
      axisId: axisId
      // personaId: 0,
      // axisId: 8
    });
    
    getDS([get])
      .then(response_key => {
        // 性別情報を取り出す
        const sexId = window.location.hash.split("s=")[1];
        const sex = Number(sexId.slice(0, 1));
        sessionStorage.setItem("se", sex);
        sessionStorage.setItem("sy", localStorage.getItem("sy"));
        // 調査データ判定
        let survey;
        let query = JSON.parse(response_key[get]);
        console.log("query",query);
        let assumed_surveys = JSON.parse(localStorage.getItem("sy")).filter((sv)=> {
          if(sv.name.indexOf(query.switch_survey) !== -1 && Number(sv.sex) === sex){
            return sv
          }
        });
        survey = assumed_surveys[0].name;

        localStorage.removeItem("ss");
        localStorage.removeItem("sv");
        localStorage.setItem("ss", formatSs(survey, sex));
        localStorage.setItem("sv", survey);
        sessionStorage.setItem("ss", formatSs(survey, sex));
        this.setState({ graphData: JSON.parse(response_key[get]) });
        const filterData = { 
          "filters": JSON.parse(response_key[get]),
          "survey": formatSurvey(),
          "sex": Number(sex)
        };
        new Api().post("/api/v1/filter", filterData, response_filter => {

          // ブラウザタブタイトルを変更する
          let s = formatSurveyYear();
          let se = Number(JSON.parse(sessionStorage.getItem("ss")).sex)? "男性" : "女性";      
          document.title = `${s}${se}|${response_filter.data.persona_list[axisId].persona_axis_name}：${response_filter.data.persona_list[axisId].persona_list[personaId - 1].persona_name}|スケルトン詳細`;
          
          // TODO 暫定対応 2019年版の保存データにswich系リストが含まれない場合には無条件で初期化する
          let switch_lifestyle_list = [];
          if (response_filter.data.filter_state.switch_lifestyle_list?.length){
            switch_lifestyle_list = response_filter.data.filter_state.switch_lifestyle_list;
          }
          let switch_purchasing_behavior_list = [];
          if (response_filter.data.filter_state.switch_purchasing_behavior_list?.length){
            switch_purchasing_behavior_list = response_filter.data.filter_state.switch_purchasing_behavior_list;
          }

          self.setState({
            requestBasic: response_filter.data.filter_state.basic_list,
            requestLifestyle: response_filter.data.filter_state.lifestyle_list,
            requestBrand: response_filter.data.filter_state.purchasing_behavior_list,
            requestPersona: response_filter.data.filter_state.persona_list,
            responseBasic: response_filter.data.basic_list,
            responseBrand: response_filter.data.purchasing_behavior_list,
            responseLifestyle: response_filter.data.lifestyle_list,
            responseFreeform: response_filter.data.freeform_list,
            responsePersona: response_filter.data.persona_list,
            lifestyleDisplayList: switch_lifestyle_list,
            brandDisplayList: switch_purchasing_behavior_list,
          });
          self.props.setPersonaFilterState(response_filter.data.filter_state);
          //ポジショニングマップサムネイル情報を取得
          new Api().post("/api/v1/pmaps_info", {"survey": formatSurvey(), "sex": Number(sessionStorage.getItem("se"))}, response_thumbnail => {
            let pmap = [];
            for (let i = 0; i < response_thumbnail.data.maps.length; i++) {
              let zeroPaddedId = ('00' + response_thumbnail.data.maps[i].graph_id).slice(-2);
              pmap.push(
                {
                  "title": response_thumbnail.data.maps[i].graph_label,
                  "image_src": `${process.env.PUBLIC_URL}/images/pmap_filter/pmap_${response_filter.data.purchasing_behavior_list[0].survey_name}_${zeroPaddedId}_00.png`,
                  "id": zeroPaddedId
                }
              );
            }
            self.setState({ pmapPersona: pmap });
            for (let i = 0; i < pmap.length; i++) {
              if (pmap[i].image_src === `${process.env.PUBLIC_URL}/images/pmap_filter/pmap_${response_filter.data.purchasing_behavior_list[0].survey_name}_${pmap[i].id}_00.png`) {
                new Api().post('/api/v1/pmap_with_filter', {filters: JSON.parse(response_key[get]), "graph_id": pmap[i].id, "height": 250, "width": 350, "disp_label": false, "survey": formatSurvey(), "sex": Number(sessionStorage.getItem("se"))},
                  response_personaThumbnail => {
                    pmap[i].image_src = response_personaThumbnail.data.image_src;
                    self.setState({ pmapPersona: pmap })
                  });
              }
              else { continue; }
            }
          })
        });
        //KWマップのノードとラベル情報を取得
        new Api().post("/api/v1/kwmap", {"filters": JSON.parse(response_key[get]), "survey": formatSurvey(), "sex": Number(sessionStorage.getItem("se"))}, 
        response_keyword => {
          self.setState({
            nodeList: response_keyword.data.nodes,
            linkList: response_keyword.data.links
          });
        });

        //プリファレンスマップ情報を取得
        new Api().post("/api/v1/pref", {"filters": JSON.parse(response_key[get]), "image_ratio": 0.8.toExponential, "survey": formatSurvey(), "sex": Number(sessionStorage.getItem("se"))}, 
        response => {
          self.setState({ prefImage: response.data.image_src });
        });
      })
      .catch((error) => {
          console.log("error");
          localStorage.removeItem("ci");
          localStorage.removeItem("tr");
          localStorage.removeItem("it");
          localStorage.removeItem("sy");
          localStorage.removeItem("ss");
          localStorage.removeItem("sv");
          localStorage.removeItem("se");
      })
  }

  /**
   * (React組み込み関数)絞り込み結果が更新された時、画面表示用にデータを整理する
   * ※Top.jsにcomponentDidUpdate()の公式URLを記載
   * @param {object} prevProps - 条件一覧モーダル上で行われた絞り込み条件
   * @param {object} basic_list - 条件一覧モーダル上で行われた基本属性の絞り込み条件
   * @param {object} lifestyle_list - 条件一覧モーダル上で行われたライフスタイルの絞り込み条件
   * @param {object} purchasing_behavior_list - 条件一覧モーダル上で行われた購買行動の絞り込み条件
   * @param {object} persona_list - 条件一覧モーダル上で行われたペルソナスケルトンの絞り込み条件
   * @param {object} filterData - filterAPIのリクエスト
   */
  componentDidUpdate(prevProps) {
    if (prevProps.listOfReFilterInPersona !== this.props.listOfReFilterInPersona) {
      let basic_list = this.props.listOfReFilterInPersona.basic_list;
      let lifestyle_list = this.props.listOfReFilterInPersona.lifestyle_list;
      let persona_list = this.props.listOfReFilterInPersona.persona_list;
      let purchasing_behavior_list = this.props.listOfReFilterInPersona.purchasing_behavior_list;

      this.setState({
        requestBasic: basic_list,
        requestLifestyle: lifestyle_list,
        requestBrand: purchasing_behavior_list,
        requestPersona: persona_list,
        graphData: {
          basic_list,
          lifestyle_list,
          persona_list,
          purchasing_behavior_list
        },
      });
      let filterData = {
        basic_list,
        lifestyle_list,
        persona_list,
        purchasing_behavior_list,
        switch_persona_detail: true,
      };
      this.requestAjax.bind(this, filterData)();
      this.kwMapAjax.bind(this, filterData)();
      this.pmapPersonaAjax.bind(this)();
      this.prefAjax.bind(this, filterData)();
    }
  }

  /**
   * 各カテゴリで絞り込みが行われる度に、更新されたリクエストで絞り込みを行う
   * @param {object} data - 絞り込み条件
   * @param {string} jwt - 認証情報
   */
  requestAjax(data) {
    let self = this;
    self.setState({ responseBasic: "" });
    new Api().post("/api/v1/filter", { filters: data, "survey": formatSurvey(), "sex": Number(sessionStorage.getItem("se"))}, 
    response => {
      self.setState({
        requestBasic: response.data.filter_state.basic_list,
        requestLifestyle: response.data.filter_state.lifestyle_list,
        requestBrand: response.data.filter_state.purchasing_behavior_list,
        responseBasic: response.data.basic_list,
        responseBrand: response.data.purchasing_behavior_list,
        responseLifestyle: response.data.lifestyle_list,
        responsePersona: response.data.persona_list,
        responseFreeform: response.data.freeform_list,
      });
      self.props.setPersonaFilterState(response.data.filter_state);
    });
  }

  /**
   * 各カテゴリで絞り込みが行われる度に、KWマップ情報を取得する
   * @param {object} data - 絞り込み条件
   * @param {string} jwt - 認証情報
   */
  kwMapAjax(data) {
    let self = this;
    new Api().post("/api/v1/kwmap", { filters: data, "survey": formatSurvey(), "sex": Number(sessionStorage.getItem("se")) }, 
    response => {
      self.setState({
        nodeList: response.data.nodes,
        linkList: response.data.links
      });
    })
  }

  /**
   * 各カテゴリで絞り込みが行われる度、ポジショニングマップサムネイル情報を取得する
   * @param {object} data - 絞り込み条件
   * @param {string} jwt - 認証情報
   */
  pmapPersonaAjax() {
    let self = this;

    new Api().post("/api/v1/pmaps_info", {"survey": formatSurvey(), "sex": Number(sessionStorage.getItem("se")) }, 
    response => {
      self.setGraph.bind(this, response.data.maps)();
    });
  }

  /**
   * クリックされたサムネイルに該当する高画質なポジショニングマップを取得する
   * @param {string} id - ポジショニングマップのid
   * @param {string} grephId - ポジショニングマップid
   * @param {string} jwt - 認証情報
   * @param {number} zeroPaddedBrand - ゼロパディング済のポジショニングマップid
   */
  setImage(id) {
    const self = this;
    new Api().post('/api/v1/pmap_with_filter', 
    { filters: this.state.graphData, "graph_id": id, "height": 700, "width": 1000, "disp_label": true, "survey": formatSurvey(), "sex": Number(sessionStorage.getItem("se")) },
    response => {
      self.setState({
        labelImage: response.data.image_src,
        id: id
      });
    });
  }

  /**
   * 各カテゴリで絞り込みが行われる度、プリファレンスマップ情報を取得する
   * @param {object} data - 絞り込み条件
   * @param {string} jwt - 認証情報
   */
  prefAjax(data) {
    const self = this;
    new Api().post("/api/v1/pref", { filters: data, image_ratio: 0.8, "survey": formatSurvey() }, response => {
      self.setState({ prefImage: response.data.image_src });
    });
  }

  /**
   * ペルソナスケルトンアイコン、プロフィール、カスタマージャーニーを表示する
   * @return {JSX} ペルソナスケルトンアイコン、プロフィール、カスタマージャーニーを表示用HTMLを返す
   */
  personaDetailGenerator() {
    return (
      <div>
        <Row>
          <Col xl={2} lg={12} className="d-flex align-items-center flex-column ">
            <img
              src={`${process.env.PUBLIC_URL}/images/persona_icon/${this.state.responsePersona[this.state.axisId].persona_list[this.state.personaId].persona_image_url}`} alt="" width="200" height="300"
              className="py-2 px-2"
            /><br />
            <b style={{ fontSize: "150%" }}>
              {this.state.responsePersona[this.state.axisId].persona_axis_name}：<br />
              {this.state.responsePersona[this.state.axisId].persona_list[this.state.personaId].persona_name}<br /></b>
              （{this.state.responsePersona[this.state.axisId].persona_list[this.state.personaId].persona_number}人
              /{(Math.round(this.state.responsePersona[this.state.axisId].persona_list[this.state.personaId].specialization_coef * 100) / 100).toFixed(2)}）
          </Col>
          <Col xl={4} lg={12} className="pl-0 ml-0 d-flex flex-column">
            <b style={{ fontSize: "150%", textAlign: "center", borderBottom: "medium solid #000000" }}>プロフィール</b>
            <div size="xl" style={{ marginTop: 8 }}>
              <div style={{ height: "300px", overflowY: "hidden", fontSize: "15pt" }} >
                <div dangerouslySetInnerHTML={{ __html: this.state.responsePersona[this.state.axisId].persona_list[this.state.personaId].persona_profile }} />
              </div>
            </div>
          </Col>
          <Col xl={6} lg={12}>
            <b className="pl-0 ml-0 d-flex flex-column" style={{ fontSize: "150%", textAlign: "center", borderBottom: "medium solid #000000" }}>カスタマージャーニー</b>
            <div size="xl" style={{ marginTop: 8, }}>
              <div style={{ textAlign: "center" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/persona_icon/${this.state.responsePersona[this.state.axisId].persona_list[this.state.personaId].persona_customer_journey_image_url}`} alt="" width="900" height="300"
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  /**
 * ポジショニングマップ静的サムネイルリストを画面描画用に整理する
 * @param {Array} graphData - 親から取得したポジショニングマップサムネイルリスト 
 * @param {Array} pmap - 画面描画用に情報を整理したポジショニングマップサムネイルリスト
 * @param {string} zeroPaddedId - ポジショニングマップサムネイルid
 */
  setGraph(graphData) {
    let pmap = [];
    for (let i = 0; i < graphData.length; i++) {
      let zeroPaddedId = ('00' + graphData[i].graph_id).slice(-2);
      pmap.push(
        {
          "title": graphData[i].graph_label,
          "image_src": `${process.env.PUBLIC_URL}/images/pmap_filter/pmap_${formatSurvey()}_${zeroPaddedId}_00.png`,
          "id": zeroPaddedId
        }
      );
    }
    this.setState({ pmapPersona: pmap });
    this.setThumbnail.bind(this, pmap)();
  }

  /**
   * ペルソナプロットが重なったサムネイルを取得する
   * @param {Array} grephList - ポジショニングマップサムネイルリスト
   * @param {string} jwt - 認証情報
   * @param {Array} pmap - ポジショニングマップサムネイルリストのコピー
   */
  setThumbnail(graphList) {
    let self = this;
    //pmapPersona内に保存されている画像が静的サムネイルだったら、APIからペルソナプロットがのったマップを貰う
    let pmap = graphList;
    for (let i = 0; i < pmap.length; i++) {
      if (pmap[i].image_src === `${process.env.PUBLIC_URL}/images/pmap_filter/pmap_${formatSurvey()}_${pmap[i].id}_00.png`) {
        new Api().post("/api/v1/pmap_with_filter", { filters: this.state.graphData, graph_id: pmap[i].id, height: 250, width: 350, disp_label: false, "survey": formatSurvey(), "sex": Number(sessionStorage.getItem("se")) }, response => {
          pmap[i].image_src = response.data.image_src;
          self.setState({ pmapPersona: pmap })
        });
      }
      else { continue; }
    }
  }

  /**
   * 実際にウィンドウに表示する
   * @return {JSX} ペルソナスケルトン詳細ページ表示用HTMLを返す
   */
  render() {
    let surveys = JSON.parse(localStorage.getItem("sy"));
    if (this.state.responsePersona === "") {
      return (
        <div className="d-flex justify-content-center mt-4 pt-4">
          <div className="spinner-border text-secondary" style={{ width: "5rem", height: "5rem" }} role="status">
            <span className="sr-only">Loading...</span>
          </div>
            <Toast style={{ position: 'absolute', top: 10, margin: "auto", color: "#4169e1", width: "600px", height: "100px", backgroundColor: "#e0ffff" }}>
              <Toast.Body>リクエストを処理しています。この処理には時間が掛かる場合があります。しばらくお待ちください。</Toast.Body>
            </Toast>
        </div>
      );
    }
    else if (this.state.responseBasic === "" || this.state.requestLifestyle === "" || this.state.requestBrand === "") {
      return (
        <Container fluid style={{ backgroundColor: "#f5f5f5" }} className="print">
          <div><b style={{fontSize: "150%", position: "absolute", right: 20}}>                
            表示データ：{(() => {
              const sex = Number(sessionStorage.getItem('se')) === 0 ? "女性" : "男性";
              for(let i = 0; i < surveys.length; i++){
                if(surveys[i].name === formatSurvey()){
                  if(formatSurveyYear() === "2022"){
                    return `${sex} ／ ${surveys[i].label}`;
                  }
                  else if(formatSurveyYear() === "2023"){
                    return `${sex} ／ ${surveys[i].label}`;
                  }
                  else{
                    return surveys[i].label;
                  }
                }
              }
            })()}
          </b></div>
          <Tabs>
            <TabList>
              <Tab>ペルソナ詳細</Tab>
              <Tab>多変量解析</Tab>
              <Tab>自由記述</Tab>
            </TabList>
            <TabPanel style={{ backgroundColor: "#f5f5f5" }}>
              {this.personaDetailGenerator()}
              <div className="d-flex justify-content-center mt-4 pt-4" style={{ backgroundColor: "#f5f5f5", height: "410px" }}>
                <div className="spinner-border text-secondary" style={{ width: "5rem", height: "5rem" }} role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                  <Toast style={{ position: 'absolute', top: 10, margin: "auto", color: "#4169e1", width: "600px", height: "100px", backgroundColor: "#e0ffff" }}>
                    <Toast.Body>リクエストを処理しています。この処理には時間が掛かる場合があります。しばらくお待ちください。</Toast.Body>
                  </Toast>
              </div>
            </TabPanel>
            <TabPanel style={{ backgroundColor: "#f5f5f5" }}>
              {/* 多変量解析 */}
              {this.personaDetailGenerator()}
              <div className="d-flex justify-content-center mt-4 pt-4" style={{ backgroundColor: "#f5f5f5", height: "410px" }}>
                <div className="spinner-border text-secondary" style={{ width: "5rem", height: "5rem" }} role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                  <Toast style={{ position: 'absolute', top: 10, margin: "auto", color: "#4169e1", width: "600px", height: "100px", backgroundColor: "#e0ffff" }}>
                    <Toast.Body>リクエストを処理しています。この処理には時間が掛かる場合があります。しばらくお待ちください。</Toast.Body>
                  </Toast>
              </div>
            </TabPanel>
            <TabPanel style={{ backgroundColor: "#f5f5f5" }}>
              {this.personaDetailGenerator()}
              <div className="d-flex justify-content-center mt-4 pt-4" style={{ backgroundColor: "#f5f5f5", height: "410px" }}>
                <div className="spinner-border text-secondary" style={{ width: "5rem", height: "5rem" }} role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                  <Toast style={{ position: 'absolute', top: 10, margin: "auto", color: "#4169e1", width: "600px", height: "100px", backgroundColor: "#e0ffff" }}>
                    <Toast.Body>リクエストを処理しています。この処理には時間が掛かる場合があります。しばらくお待ちください。</Toast.Body>
                  </Toast>
              </div>
            </TabPanel>
          </Tabs>
        </Container>
      );
    }
    else {
      return (
        <Container fluid style={{ backgroundColor: "#f5f5f5" }} className="print">
          <div><b style={{fontSize: "150%", position: "absolute", right: 20}}>
            表示データ：{(() => {
              const sex = Number(sessionStorage.getItem('se')) === 0 ? "女性" : "男性";
              for(let i = 0; i < surveys.length; i++){
                if(surveys[i].name === formatSurvey()){
                  if(formatSurveyYear() === "2022"){
                    return `${sex} ／ ${surveys[i].label}`;
                  }
                  else if(formatSurveyYear() === "2023"){
                    return `${sex} ／ ${surveys[i].label}`;
                  }
                  else{
                    return surveys[i].label;
                  }
                }
              }
            })()}
          </b></div>
          <Tabs>
            <TabList>
              <Tab>ペルソナ詳細</Tab>
              <Tab>多変量解析</Tab>
              <Tab>自由記述</Tab>
            </TabList>
            <TabPanel style={{ backgroundColor: "#f5f5f5" }}>
              {this.personaDetailGenerator()}
              <Row>
                <Col md={4} className="px-3 d-flex flex-column" style={{ backgroundColor: "#f5f5f5" }}>
                  <b style={{ fontSize: "150%", textAlign: "center", borderBottom: "medium solid #000000" }} className="mb-2">基本属性</b>
                  <BasicPropertyTable
                    requestBasic={this.state.requestBasic}
                    responseBasic={this.state.responseBasic}
                    setRequestBasic={this.setRequestBasic}
                  />
                </Col>
                <Col md={4} className="px-3 d-flex flex-column" style={{ backgroundColor: "#f5f5f5" }}>
                  <b style={{ fontSize: "150%", textAlign: "center", borderBottom: "medium solid #000000" }} className="mb-2">価値観</b>
                  <LifeStyleTable
                    requestLifestyle={this.state.requestLifestyle}
                    responseLifestyle={this.state.responseLifestyle}
                    setRequestLifestyle={this.setRequestLifestyle}
                    lifestyleDisplayList={this.state.lifestyleDisplayList}
                    lifestylePrevDisplayList={this.state.lifestylePrevDisplayList}
                    setDisplayLifestyle={this.setDisplayLifestyle}
                    setPrevDisplayLifestyle={this.setPrevDisplayLifestyle}
                  />
                </Col>
                <Col md={4} className="px-3 d-flex flex-column mb-2" style={{ backgroundColor: "#f5f5f5" }}>
                  <b style={{ fontSize: "150%", textAlign: "center", borderBottom: "medium solid #000000" }} className="mb-2">ブランド選択</b>
                  <BrandTable
                    requestBrand={this.state.requestBrand}
                    responseBrand={this.state.responseBrand}
                    setRequestBrand={this.setRequestBrand}
                    brandDisplayList={this.state.brandDisplayList}
                    brandPrevDisplayList={this.state.brandPrevDisplayList}
                    setDisplayBrand={this.setDisplayBrand}
                    setPrevDisplayBrand={this.setPrevDisplayBrand}
                  />
                </Col>
              </Row>
            </TabPanel>
            <TabPanel style={{ backgroundColor: "#f5f5f5" }}>
              {/* 多変量解析 */}
              {this.personaDetailGenerator()}
              <Row className="d-flex">
                {formatSurveyYear() === "2023" ?
                  <Col md={12} sm={12}>
                    <PositioningMap
                      pmapPersona={this.state.pmapPersona}
                      setImage={this.setImage}
                      // setImage={this.setImage.bind(this)}
                      labelImage={this.state.labelImage}
                      id={this.state.id}
                    />
                  </Col>:
                  <Col md={7} sm={12}>
                    <PositioningMap
                      pmapPersona={this.state.pmapPersona}
                      setImage={this.setImage}
                      // setImage={this.setImage.bind(this)}
                      labelImage={this.state.labelImage}
                      id={this.state.id}
                    />
                  </Col>
                }
                <Col md={5} sm={12}  style={formatSurveyYear() === "2023" ? {display:"none"}:{display:"block"}}>
                  <div>
                    <MultivariateAnalysis prefImage={this.state.prefImage} />
                  </div>
                </Col>
              </Row>
            </TabPanel>
            <TabPanel style={{ backgroundColor: "#f5f5f5" }}>
              {this.personaDetailGenerator()}
              <KeywordMap
                responseFreeform={this.state.responseFreeform}
                nodes={this.state.nodeList}
                links={this.state.linkList}
              />
            </TabPanel>
          </Tabs>
        </Container>
      );
    }
  }
}
export default PersonaDetail;