import React from "react";
import { Container, Row, Col, Table, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./App.css";
import { setDS } from "./DataStore";
import {formatSurvey} from "./common";

/**
 * ペルソナスケルトンテーブル描画クラス
 */
class PersonaImage extends React.Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      requestPersona: "",
      responsePersona: "",
      axis1: 0,
      axis2: 1,
      axis3: 2,
      axis4: 3,
      displayList: "",
      displayListPrev: "",
      compressedKey: "",
      axisIndex: "",
      personaIndex: "",
      queryList: "",
      compressedList: "",
      jsonList: "",
      numberOfPersonaCategory: 8,
      numberOfPersona: 32,
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModalPersonaImage = this.openModalPersonaImage.bind(this);
    this.closeModalPersonaFilter = this.closeModalPersonaFilter.bind(this);
    this.stateChange = this.stateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.defaultChecked = this.defaultChecked.bind(this);
    this.iconSwitcher = this.iconSwitcher.bind(this);
    this.defaultCheckedAxis = this.defaultCheckedAxis.bind(this);
    this.selectAxisColor = this.selectAxisColor.bind(this);
    this.displayChange = this.displayChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.allUnCheckSwitchbox = this.allUnCheckSwitchbox.bind(this);
    this.setData = this.setData.bind(this);
}

  /**
   * (React組み込み関数)絞り込み結果の変更有無が分かる
   * ※Navi.jsにshouldComponentUpdate()の公式URLを記載
   * @param {object} nextProps - 次に更新される絞り込み結果
   * @return {boolean} 変更有無
   */
  shouldComponentUpdate(nextProps) {
    this._isMounted = true;
    return !(this.state.requestPersona === nextProps.requestPersona);
  }

  /**
   * (React組み込み関数)初期画面表示のためのデータを整理する
   * ※Top.jsにUNSAFE_componentWillMount()の公式URLを記載
   * @param {Array} persona_list - 絞り込みのリクエスト
   * @param {Array} re_persona_list - 絞り込みのレスポンス
   * @param {Array} axisArray - トップページに表示させる4ペルソナの軸リスト
   * @param {Array} requestList - レスポンスの並び替え順に合わせて並び替えたリクエスト
   * @param {JSON} basicData - 基本属性の絞り込み条件
   * @param {JSON} lifeData - ライフスタイルの絞り込み条件
   * @param {JSON} personaData - ペルソナスケルトンの絞り込み条件
   * @param {JSON} brandData - 購買行動の絞り込み条件
   * @param {JSON} queryStrList - URLクエリ文字列のリスト
   * @param {JSON} jsonStrList - URLクエリ文字列のJSON形式のリスト
   * @param {object} obj - ペルソナスケルトン絞り込み条件のディープコピー
   * @param {number} axis_id - ペルソナスケルトン軸id
   * @param {number} persona_id - ペルソナスケルトンid
   */
  UNSAFE_componentWillMount(){
    if (!this._isMounted) {

      let persona_list = this.props.requestPersona;
      let re_persona_list = this.props.responsePersona;
      this.setState({
        numberOfPersonaCategory: this.props.responsePersona.length,
        numberOfPersona: this.props.responsePersona.length * 4
      });

      //レスポンスpropsをorder順にソート
      re_persona_list.sort((a,b) => {
          if(a.persona_axis_order < b.persona_axis_order) return -1;
          if(a.persona_axis_order > b.persona_axis_order) return 1;
          return 0;
      });
      //表示させる4ペルソナの軸を設定する
      let axisArray = [];
      let count = 0;
      re_persona_list.forEach(d => {
        if (count < 4){
          if(this.props.personaDisplayList !== undefined){
            for(let i = 0; i < this.props.personaDisplayList.length; i++){
              if(d.persona_axis_id === this.props.personaDisplayList[i]){
                axisArray.push(d);
                count++;
              }
              else{continue;}
            }
          }
          else{
            let switch_persona_list = [10, 11, 8, 9];
            // let switch_persona_list = [0, 1, 2, 3];
            for(let i = 0; i < switch_persona_list.length; i++){
              if(d.persona_axis_id === switch_persona_list[i]){
                axisArray.push(d);
                count++;
              }
              else{continue;}
            }
          }
        }
      });
      //表示切替保存以前の復元に対応させる
      if(this.props.personaDisplayList !== undefined){
        this.setState({
          //上位4つresponsePersonaのindexを格納
          axis1: this.props.personaDisplayList[0],
          axis2: this.props.personaDisplayList[1],
          axis3: this.props.personaDisplayList[2],
          axis4: this.props.personaDisplayList[3],
          displayList: this.props.personaDisplayList,
          displayListPrev: this.props.personaPrevDisplayList,
        });
      }
      else{
        this.setState({
          //上位4つresponsePersonaのindexを格納
          axis1: 0,
          axis2: 1,
          axis3: 2,
          axis4: 3,
          displayList: [0, 1, 2, 3],
          displayListPrev: this.props.personaPrevDisplayList,
        });
      }

      //特徴係数から項目を並べ替える
      for(let i = 0; i < re_persona_list.length; i++){    
        re_persona_list[i].persona_list.sort((a, b) => {
          if(a.specialization_coef > b.specialization_coef) return -1;
          if(a.specialization_coef < b.specialization_coef) return 1;
          return 0;
        });
      }
      //レスポンスのソートに合わせてリクエストpropsをソート
      let requestList = [];
      for(let i = 0; i < re_persona_list.length; i++){
        for(let j = 0; j < re_persona_list.length; j++){
          if(re_persona_list[i].persona_axis_id === persona_list[j].axis_id){
            requestList.push(persona_list[j]);
          }
          else{continue;}
        }
      }
      this.setState({
        requestPersona: requestList,
        prevPersonaList: requestList,
        responsePersona: re_persona_list
      });
      //propsをバラバラに取り出す
      let basicData = this.props.filterJson.basic_list;
      let lifeData = this.props.filterJson.lifestyle_list;
      let personaData = this.props.filterJson.persona_list;
      let brandData = this.props.filterJson.purchasing_behavior_list;
      let self = this;
      // 全部falseのベースを作る
      for (let i = 0; i < this.props.responsePersona.length; i++) {
        for (let j = 0; j < 4; j++) {
          personaData[i].persona_filter_list[j].persona_filter_selected = 0
        }
      }
      let queryStrList = [];
      let jsonStrList = [];
      // ちょっとずつ変えてpush
      for (let i = 0; i < this.props.responsePersona.length * 4; i++) {
        // personaDataをobjにコピー
        let obj = JSON.parse(JSON.stringify(personaData));
        // 軸インデックスは4で割った商
        let axis_id = Math.floor(i/4);
        // ペルソナインデックスは4で割った余り
        let persona_id = i % 4;
        obj[axis_id].persona_filter_list[persona_id].persona_filter_selected = 1;
        let propsData = {
          "basic_list": basicData,
          "lifestyle_list": lifeData,
          "persona_list": obj,
          "purchasing_behavior_list": brandData,
          "switch_lifestyle_list": this.props.filterJson.switch_lifestyle_list,
          "switch_purchasing_behavior_list": this.props.filterJson.switch_purchasing_behavior_list,
          "switch_persona_detail": true,
          "switch_survey": formatSurvey()
          // "switch_clicked_persona_axis_id": obj[axis_id].axis_id,
          // "switch_clicked_persona_id": Number(obj[axis_id].persona_filter_list[persona_id].persona_filter_id),
        }
        queryStrList.push(JSON.stringify(propsData));
        jsonStrList.push(propsData);
      }
      this.setState({
        queryList: queryStrList,
        jsonList: jsonStrList
      });
      setDS(queryStrList)
      .then(response_key => {
        self.setState({compressedList: response_key});
      })
    }
  }

  /**
   * (React組み込み関数)絞り込み結果が更新された時、画面表示用にデータを整理する
   * ※Top.jsにcomponentDidUpdate()の公式URLを記載
   * @param {Array} prevProps - 今までの絞り込み結果
   * @param {Array} persona_list - 絞り込みのリクエスト
   * @param {Array} re_persona_list - 絞り込みのレスポンス
   * @param {Array} requestList - レスポンスの並び替え順に合わせて並び替えたリクエスト
   * @param {Array} displayResponseList - トップページに表示させる4ペルソナの軸リスト
   * @param {number} count - トップページに表示させるペルソナ軸リストのカウント
   * @param {JSON} basicData - 基本属性の絞り込み条件
   * @param {JSON} lifeData - ライフスタイルの絞り込み条件
   * @param {JSON} personaData - ペルソナスケルトンの絞り込み条件
   * @param {JSON} brandData - 購買行動の絞り込み条件
   * @param {JSON} queryStrList - URLクエリ文字列のリスト
   * @param {JSON} jsonStrList - URLクエリ文字列のJSON形式のリスト
   * @param {object} obj - ペルソナスケルトン絞り込み条件のディープコピー
   * @param {number} axis_id - ペルソナスケルトン軸id
   * @param {number} persona_id - ペルソナスケルトンid
   */
  componentDidUpdate(prevProps){
    if(prevProps.responsePersona !== this.props.responsePersona || this.props.filterJson !== prevProps.filterJson){
      this._isMounted = true;
      let persona_list = this.props.requestPersona;
      let re_persona_list = this.props.responsePersona;


      //軸order順にソート
      re_persona_list.sort((a,b) => {
        if(a.persona_axis_order < b.persona_axis_order) return -1;
        if(a.persona_axis_order > b.persona_axis_order) return 1;
        return 0;
      });
      //特徴係数から項目を並べ替える
      for(let i = 0; i < re_persona_list.length; i++){    
        re_persona_list[i].persona_list.sort((a, b) => {
          if(a.specialization_coef > b.specialization_coef) return -1;
          if(a.specialization_coef < b.specialization_coef) return 1;
          return 0;
        });
      }
      //レスポンスのソートに合わせてリクエストpropsをソート
      let requestList = [];
      for(let i = 0; i < re_persona_list.length; i++){
        for(let j = 0; j < re_persona_list.length; j++){
          if(re_persona_list[i].persona_axis_id === persona_list[j].axis_id){
            requestList.push(persona_list[i]);
          }
          else{continue;}
        }
      }
      this.setState({
        requestPersona: requestList,
        prevPersonaList: requestList,
        responsePersona: re_persona_list
      });
      let displayResponseList = [];
      let count = 0;
      for(let i = 0; i < re_persona_list.length; i++){
        if(count < 4){
          if(this.state.displayList[count] === re_persona_list[i].persona_axis_id){
            displayResponseList.push(re_persona_list[i].persona_axis_id);
            count++;
          }
          else{continue;}
        }
      }
      this.setState({displayList: displayResponseList});
      //propsをバラバラに取り出す
      let basicData = this.props.filterJson.basic_list;
      let lifeData = this.props.filterJson.lifestyle_list;
      let personaData = JSON.parse(JSON.stringify(this.props.filterJson.persona_list));
      let brandData = this.props.filterJson.purchasing_behavior_list;
      let self = this;
      // 全部falseのベースを作る
      for (let i = 0; i < this.state.numberOfPersonaCategory; i++) {
        for (let j = 0; j < 4; j++) {
          personaData[i].persona_filter_list[j].persona_filter_selected = 0
        }
      }
      let queryStrList = [];
      let jsonStrList = [];
      // ちょっとずつ変えてpush
      for (let i = 0; i < this.state.numberOfPersona; i++) {
        // personaDataをobjにコピー
        let obj = JSON.parse(JSON.stringify(personaData));
        // 軸インデックスは4で割った商
        let axis_id = Math.floor(i/4);
        // ペルソナインデックスは4で割った余り
        let persona_id = i % 4;
        obj[axis_id].persona_filter_list[persona_id].persona_filter_selected = 1;
        let propsData = {
            "basic_list": basicData,
            "lifestyle_list": lifeData,
            "persona_list": obj,
            "purchasing_behavior_list": brandData,
            "switch_lifestyle_list": this.props.filterJson.switch_lifestyle_list,
            "switch_purchasing_behavior_list": this.props.filterJson.switch_purchasing_behavior_list,
            "switch_persona_detail": true,
            "switch_survey": formatSurvey()
            // "switch_clicked_persona_axis_id": obj[axis_id].axis_id,
            // "switch_clicked_persona_id": Number(obj[axis_id].persona_filter_list[persona_id].persona_filter_id),
        }
        queryStrList.push(JSON.stringify(propsData));
        jsonStrList.push(propsData);
      }
      this.setState({
        queryList: queryStrList,
        jsonList: jsonStrList,
        compressedList: ""
      });
      this.props.setCompressState("start");
      setDS(queryStrList)
      .then(response_key => {
        self.setState({compressedList: response_key});
        this.props.setCompressState("end");
      })
    }
    else if(prevProps.modalState !== this.props.modalState){
      if(this.props.modalState){
        this.openModalPersonaImage.bind(this)();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * 表示切替モーダルを開いた際の、モーダル内のチェック状態を確認する
   * @param {Array} currList - ユーザが操作した最新の表示切替状態
   * @param {Array} prevList - 確定している最新の表示切替状態
   * @param {number} count - トップページに表示させるペルソナ軸リストのカウント
   */
  openModalPersonaImage(){
    let currList = [...this.state.displayList];
    let prevList = [...this.state.displayListPrev];
    //displayListを4つにしたい
    if(currList.length !== 4){    
      this.setState({displayList: prevList});
      for(let i = 0; i < this.state.responsePersona.length; i++){
        document.getElementById(this.state.responsePersona[i].persona_axis_name).checked = false;
      }
      //displayList から、チェックボックスを書き換える
      for(let j = 0; j < this.state.responsePersona.length; j++){
        for(let i = 0; i < prevList.length; i++){
          if(this.state.responsePersona[j].persona_axis_id === prevList[i]){
            document.getElementById(this.state.responsePersona[j].persona_axis_name).checked = true;
          }
          else{continue;}
        }
      }
    }
    //4つだけど切替ボタン押してないことがある
    else{
      //表示内容とクリック内容に違いが無いか、axis1~4とidが揃ってるかで確認
      let count = [];
      for(let i = 0; i < prevList.length; i++){
        if(this.state.responsePersona[this.state.axis1].persona_axis_id === currList[i]){
          count.push(1);
        }
        else if(this.state.responsePersona[this.state.axis2].persona_axis_id === currList[i]){
          count.push(2);
        }
        else if(this.state.responsePersona[this.state.axis3].persona_axis_id === currList[i]){
          count.push(3);
        }
        else if(this.state.responsePersona[this.state.axis4].persona_axis_id === currList[i]){
          count.push(4);
        }
      }
      if(count.length !== 4){//表示内容とチェック状態が異なる(ボタン押してない時)
        this.setState({
          // 選択状態を、displayListに反映する
          displayList: prevList
        });
        for(let i = 0; i < this.state.responsePersona.length; i++){
          document.getElementById(this.state.responsePersona[i].persona_axis_name).checked = false;
        }
        //displayList から、チェックボックスを書き換える
        for(let j = 0; j < this.state.responsePersona.length; j++){
          for(let i = 0; i < prevList.length; i++){
            if(this.state.responsePersona[j].persona_axis_id === prevList[i]){
              document.getElementById(this.state.responsePersona[j].persona_axis_name).checked = true;
            }
            else{continue;}
          }
        }
      }
      else{//同じ(ボタン押した)
        this.setState({
          // 選択前状態を、displayListに反映する
          displayListPrev: currList
        });
        this.props.setPrevDisplayPersona(currList);
        for(let i = 0; i < this.state.responsePersona.length; i++){
          document.getElementById(this.state.responsePersona[i].persona_axis_name).checked = false;
        }
        //displayList から、チェックボックスを書き換える
        for(let j = 0; j < this.state.responsePersona.length; j++){
          for(let i = 0; i < currList.length; i++){
            if(this.state.responsePersona[j].persona_axis_id === currList[i]){
              document.getElementById(this.state.responsePersona[j].persona_axis_name).checked = true;
            }
            else{continue;}
          }
        }
      }
    }
  }

  /**
   * 絞り込みモーダルを閉じた際の、モーダル内のチェック状態を確認する
   * @param {Array} currList - ユーザが操作した最新のチェック状態
   * @param {Array} prevList - 絞り込み時のチェック状態
   * @param {Array} sortedCurrList - currListのチェックされている項目idを格納
   * @param {Array} sortedPrevList - prevListのチェックされている項目idを格納
   */
  closeModalPersonaFilter(){
    let currList = JSON.parse(JSON.stringify(this.state.requestPersona));
    let prevList = JSON.parse(JSON.stringify(this.state.prevPersonaList));
    for(let i = 0; i < currList.length; i++){
      for(let j = 0; j < prevList.length; j++){
        if(currList[i].axis_id === prevList[j].axis_id){
          let sortedCurrList = [];
          let sortedPrevList = [];
          sortedCurrList.push(currList[i].persona_filter_list.filter(d => d.persona_filter_selected === 1));
          sortedCurrList.sort((a,b) => {  
            if(a < b) return -1;
            if(a > b) return 1;
            return 0;
          });
          sortedPrevList.push(prevList[j].persona_filter_list.filter(d => d.persona_filter_selected === 1));
          sortedPrevList.sort((a,b) => {  
            if(a < b) return -1;
            if(a > b) return 1;
            return 0;
          });
          //「絞り込み」を押して閉じた
          if(sortedCurrList.toString() === sortedPrevList.toString()){  
          }
          //「絞り込み」を押さないで閉じた
          else if(sortedCurrList.toString() !== sortedPrevList.toString()){
            this.setState({requestPersona: prevList});
            return;
          }
        }
      }
    }
  }

  /**
   * Top.jsまたはpersonaDetail.js(親)の絞り込み条件を更新する
   * @param {Array} personaData - 確定した絞り込み条件のリスト
   * @param {Array} requestData - ライフスタイルテーブル内での最新の絞り込み条件
   */
  setData(){
    let personaData = this.state.requestPersona;
    let requestData = personaData.map(d => {
      return d;
    });
    this.props.setRequestPersona(requestData);
    this.setState({prevPersonaList: personaData});
    this.closeModal.bind(this)();
  }


  /**
   * ペルソナスケルトン軸idから軸の色を判断する
   * @param {number} axisId 軸id
   * @return {string} HTMLカラーコード
   */
  selectAxisColor(axisId){
    if(axisId === 0){//お金の使い方
      return "#ffe600"
    }
    else if(axisId === 1){//美しさ
      return "#9a005b"
    }
    else if(axisId === 2){//ストレス減
      return "#a9a500"
    }
    else if(axisId === 3){//生きがい
      return "#8fc31f"
    }
    else if(axisId === 4){//恋愛観
      return "#ee869a"
    }
    else if(axisId === 5){//購買情報源
      return "#00aebb"
    }
    else if(axisId === 6){//美容健康意識
      return "#e4007f"
    }
    else if(axisId === 7){//食生活
      return "#f08300"
    }
    else if(axisId === 8){//ニューノーマル
      return "#2e8b57"
    }
    else if(axisId === 9){//情報
      return "#191970"
    }
    else if(axisId === 10){//SDG's
      return "#e6b422"
    }
    else if(axisId === 11){//食嗜好
      return "#dc143c"
    }
  }

  /**
   * 絞り込みモーダルを閉じる
   */
  closeModal() {
    this.closeModalPersonaFilter.bind(this)();
    return this.props.close();
  }

  /**
   * ペルソナスケルトン詳細ページへ遷移する
   * @param {number} axisId -  選択された軸id
   * @param {number} personaId -  選択されたペルソナスケルトンid
  //  * @param {string} userAgent - 使用ブラウザの種類 
   * @param {number} responsePersonaIndex - 選択されたペルソナスケルトンのindex
   * @param {string} href - URL内のハッシュ情報
   * @param {string} origin - プロトコルやポートを含めたURL
   */
  handleClick(axisId, personaId){
    // let userAgent = window.navigator.userAgent.toLowerCase();
    // if(userAgent.indexOf('trident') !== -1) {
    //   return undefined;
    // }
    // else{

      let responsePersonaIndex;
      for(let i = 0; i < this.state.responsePersona[axisId].persona_list.length; i++){
        if(this.state.responsePersona[axisId].persona_list[i].persona_id == personaId){
          responsePersonaIndex = i;
        }
      }
      if(this.state.compressedList !== "" && this.state.jsonList !== "" && this.state.queryList !== "" && this.state.responsePersona !== ""){
      let href = window.location.href;
      let origin = window.location.origin;

        for(let k = 0; k < this.state.numberOfPersona; k++){
          for(let i = 0; i < this.state.numberOfPersonaCategory; i++){
            if( Number(this.state.jsonList[k].persona_list[i].axis_id) === Number(axisId)){
              for(let j = 0; j < 4; j++){
                if(Number(this.state.jsonList[k].persona_list[i].persona_filter_list[j].persona_filter_id) == Number(personaId)
                  && this.state.jsonList[k].persona_list[i].persona_filter_list[j].persona_filter_selected === 1){
                  if(this.state.responsePersona[axisId].persona_list[responsePersonaIndex].persona_number === 0){
                    return undefined;
                  }
                  else{
                    if(window.location.hash.split("#/")[1] === undefined){
                      return `${href}persona-detail?page=1&s=${Number(sessionStorage.getItem("se"))}&axis=${axisId}&persona=${personaId}&filter=${encodeURIComponent(this.state.compressedList[this.state.queryList[k]])}`;
                    }
                    else{
                      return `${origin}/#/persona-detail?page=1&s=${Number(sessionStorage.getItem("se"))}&axis=${axisId}&persona=${personaId}&filter=${encodeURIComponent(this.state.compressedList[this.state.queryList[k]])}`;
                    }
                  }
                }
                else{continue;}
              }
            }
            else{continue;}
          }

        }
      }
      else{return undefined;}
    // }

  }

  /**
   * 絞り込み結果が更新された直後のチェック状態を再現する
   * @param {number} axisId - レスポンスにおける軸id
   * @param {number} personaId - ペルソナスケルトンid
   * @param {number} requestAxis - リクエストにおける軸id
   * @return {boolean} 真偽値を返す
   */
  defaultChecked(axisId, personaId){
    let requestAxis;
    for(let i = 0; i < this.state.requestPersona.length; i++){
      if(this.state.requestPersona[i].axis_id === axisId){
        requestAxis = i;
      }
      else{continue;}
    }
    for(let i = 0; i < 4; i++){
      if(this.state.requestPersona[requestAxis].persona_filter_list[i].persona_filter_id == personaId
        && this.state.requestPersona[requestAxis].persona_filter_list[i].persona_filter_selected === 0){
          return false;
      }
      else if(this.state.requestPersona[requestAxis].persona_filter_list[i].persona_filter_id == personaId 
        && this.state.requestPersona[requestAxis].persona_filter_list[i].persona_filter_selected === 1){
          return true;
      }
    }
  }

  /**
   * 表示切替モーダルのチェックを全解除する
   */
  allUnCheckSwitchbox(){
    //全てのチェックを外す
    this.state.responsePersona.map(d => {
      document.getElementById(d.persona_axis_name).checked = false;
    });
    //表示切替リストを空にする
    this.setState({displayList: []});      
  }

  /**
   * クリックされた絞り込みチェックボックスのチェック状態を反転させる
   * @param {number} axisId - レスポンスにおける軸id
   * @param {number} personaId - ペルソナスケルトンid
   * @param {Array} changedList - チェック状態が更新されたペルソナリスト
   * @param {number} requestAxis - リクエストにおける軸id
   */
  stateChange(axisId, personaId){
    let changedList = JSON.parse(JSON.stringify(this.state.requestPersona));
    let requestAxis;
    for(let i = 0; i < this.state.requestPersona.length; i++){
      if(this.state.requestPersona[i].axis_id === axisId){
        requestAxis = i;
      }
      else{continue;}
    }
    for(let i = 0; i < changedList[requestAxis].persona_filter_list.length; i++){
      if(changedList[requestAxis].persona_filter_list[i].persona_filter_id == personaId
        && changedList[requestAxis].persona_filter_list[i].persona_filter_selected === 0){
          changedList[requestAxis].persona_filter_list[i].persona_filter_selected = 1;
          this.setState({requestPersona: changedList});
      }
      else if(changedList[requestAxis].persona_filter_list[i].persona_filter_id == personaId 
        && changedList[requestAxis].persona_filter_list[i].persona_filter_selected === 1){
          changedList[requestAxis].persona_filter_list[i].persona_filter_selected = 0;
          this.setState({requestPersona: changedList});
      }
    }
  }

  /**
   * ウィンドウに表示するペルソナスケルトン軸を切り替えるために、4軸にチェックが入っているか確認する
   * @param {Array} axisList - 表示用ぺルソナ軸4つのidが入ったリスト
   */
  displayChange = () => {
    if(this.state.displayList.length === 4){
      //display用idとresponse用配列のindexを合わせる
      let axisList = [];
      this.state.displayList.forEach(d => {
        for(let i = 0; i < this.state.responsePersona.length; i++){
          if(d === this.state.responsePersona[i].persona_axis_id){
            axisList.push(parseInt(i));
          }
        }
      });
      this.setState({axis1: axisList[0]});
      this.setState({axis2: axisList[1]});
      this.setState({axis3: axisList[2]});
      this.setState({axis4: axisList[3]});
      this.props.setDisplayPersona(axisList);
      this.closeModal();
    }
    else{
      alert("4つの軸を選択してください。");
      return false;
    }
  }

  /**
   * OVERVIEWモーダルのペルソナカテゴリ名を表示する
   * @param {Array} d - カテゴリ別のぺルソナリスト
   */
   displayOverviewPersonaCategory = (d, parentIndex) => {
     return(
      <Col xs={3} key={d.persona_axis_id} className="my-2">
        <Table>
          <thead>
            <tr>
              <th colSpan="2" className="py-1 px-2 ml-2" style={{borderTop: "0px", textAlign: "left", verticalAlign:"middle", borderBottom: `solid 15px ${this.selectAxisColor.bind(this, d.persona_axis_id)()}`}}>
                <label style={{cursor: "pointer"}} className="d-flex">
                  <input 
                    type="checkbox"
                    defaultChecked={this.defaultCheckedAxis.bind(this, d.persona_axis_id)()} 
                    onClick={this.handleChange.bind(this, d.persona_axis_name, d.persona_axis_id)}  
                    id={d.persona_axis_name}
                    className="mx-2"
                  />   
                <div style={{textAlign: "center", color: "#808080", width: "100%", marginRight: "15px"}}>
                {/* <div style={{marginLeft: "45px", color: "#808080"}}> */}
                  {d.persona_axis_name}
                </div>
                </label>
              </th>
            </tr>
          </thead>
        </Table>
        <Row>{d.persona_list.map((item, index) => this.displayOverviewPersonaItem(item, parentIndex, index))}</Row>
      </Col>
    )
  }

  /**
   * Overviewモーダルのペルソナアイテムを表示する
   * @param {Array} d - カテゴリ別のぺルソナリスト
   */
   displayOverviewPersonaItem = (d, parentIndex, index) => {
    return(
      <Col xl={6} lg={6} xs={6} key={d.persona_id}>
        {/* <Row style={{whiteSpace: "nowrap", textAlign: "center",display: "block", marginLeft: "auto", marginRight: "auto"}}><img src={`${process.env.PUBLIC_URL}/images/persona_icon/${d.persona_topimage_url}${this.iconSwitcher.bind(this, 0, 0)()}`} alt="" width="50" height="50" /></Row>
        <Row style={{whiteSpace: "nowrap", textAlign: "center", display: "block", marginLeft: "auto", marginRight: "auto"}}>{d.persona_name}</Row>
        <Row style={{whiteSpace: "nowrap", textAlign: "center",display: "block", marginLeft: "auto", marginRight: "auto"}}>{d.persona_number}人 */}
        <Row className="d-flex" style={{justifyContent: "center"}}><img src={`${process.env.PUBLIC_URL}/images/persona_icon/${d.persona_topimage_url}${this.iconSwitcher.bind(this, parentIndex, index)()}`} alt="" width="45" height="45" /></Row>
        <Row className="d-flex" style={{justifyContent: "center"}}>{d.persona_name}</Row>
        <Row className="d-flex" style={{justifyContent: "center"}}>{d.persona_number}人
          /{(Math.round(d.specialization_coef*100)/100).toFixed(2)}</Row>
      </Col>
    )
   }

  /**
   * Filterモーダルのペルソナカテゴリ名を表示する
   * @param {Array} d - カテゴリ別のぺルソナリスト
   */
   displayFilterPersonaCategory = (d, parentIndex) => {
    return(
     <Col xs={3} key={d.persona_axis_id} className="my-2">
       <Table>
       <thead>
            <tr>
              <th colSpan="2" className="py-2 px-2" style={{borderTop: "0px", color: "#808080", textAlign: "center", verticalAlign:"middle", borderBottom: `solid 15px ${this.selectAxisColor.bind(this, d.persona_axis_id)()}`}}>
                {d.persona_axis_name}
              </th>
            </tr>
          </thead>
       </Table>
       <Row>{d.persona_list.map((item, index) => this.displayFilterPersonaItem(d.persona_axis_id, item, parentIndex, index))}</Row>
     </Col>
   )
 }

 /**
  * Filterモーダルのペルソナアイテムを表示する
  * @param {Number} id - ペルソナカテゴリid
  * @param {Array} d - カテゴリ別のぺルソナリスト
  */
  displayFilterPersonaItem = (id, d, parentIndex, index) => {
   return(
     <Col xl={6} lg={6} xs={6} key={d.persona_id}>
       <Row style={{whiteSpace: "nowrap", textAlign: "center",display: "block", marginLeft: "auto", marginRight: "auto"}}>
       <label style={{cursor: "pointer"}}>
          <input 
            type="checkbox"
            id={d.persona_name}
            onClick={this.stateChange.bind(this, id, d.persona_id)}
            defaultChecked={this.defaultChecked.bind(this, id, d.persona_id)()}
            className="mx-2"
          /> 
          <img src={`${process.env.PUBLIC_URL}/images/persona_icon/${d.persona_topimage_url}${this.iconSwitcher.bind(this, parentIndex, index)()}`} alt="" width="50" height="50" />
        </label>
        </Row>
       <Row style={{whiteSpace: "nowrap", textAlign: "center", display: "block", marginLeft: "auto", marginRight: "auto"}}>{d.persona_name}</Row>
       <Row style={{whiteSpace: "nowrap", textAlign: "center",display: "block", marginLeft: "auto", marginRight: "auto"}}>{d.persona_number}人
         /{(Math.round(d.specialization_coef*100)/100).toFixed(2)}</Row>
     </Col>
   )
  }

 /**
  * TOPページにペルソナアイテムを表示する(左上)
  * @param {Object} d - カテゴリ別のぺルソナ情報
  * @param {Number} index - ぺルソナリストのindex
  * @param {String} cursor - ポインタのスタイル情報
  */
  displayTopPersonaItem1 = (d, index, cursor) => {
    let profile = d.persona_profile.replace(/(<([^>]+)>)/g,'')
    return(
      <OverlayTrigger key={d.persona_id} placement="bottom" target="outlineTooltip"
        overlay={<Tooltip>{profile.length > 100 ? (profile).slice(0,100)+"…" : profile}</Tooltip>}
      >
        <Col style={{textAlign: "center"}} xl={6} lg={6} xs={6} key={d.persona_id}>
          <a href={this.handleClick.bind(this, this.state.axis1, d.persona_id)()} target="_blank" rel="noopener noreferrer">
            <img 
              src={`${process.env.PUBLIC_URL}/images/persona_icon/${d.persona_topimage_url}${this.iconSwitcher.bind(this, this.state.axis1, index)()}`} alt="" width="50" height="50" 
              style={{cursor: d.persona_number !== 0 ? cursor : "default"}}
            /><br/>
          </a>
          <div style={{whiteSpace: "nowrap", fontSize: "90%"}}>{d.persona_name}</div>
          {d.persona_number}人
          /{(Math.round(d.specialization_coef*100)/100).toFixed(2)}
        </Col>
      </OverlayTrigger>
      )
   }


 /**
  * TOPページにペルソナアイテムを表示する(右上)
  * @param {Object} d - カテゴリ別のぺルソナ情報
  * @param {Number} index - ぺルソナリストのindex
  * @param {String} cursor - ポインタのスタイル情報
  */
  displayTopPersonaItem2 = (d, index, cursor) => {
    let profile = d.persona_profile.replace(/(<([^>]+)>)/g,'')
    return(
      <OverlayTrigger key={d.persona_id} placement="bottom" target="outlineTooltip"
        overlay={<Tooltip>{profile.length > 100 ? (profile).slice(0,100)+"…" : profile}</Tooltip>}
      >
        <Col style={{textAlign: "center"}} xl={6} lg={6} xs={6} key={d.persona_id}>
          <a href={this.handleClick.bind(this, this.state.axis2, d.persona_id)()} target="_blank" rel="noopener noreferrer">
            <img 
              src={`${process.env.PUBLIC_URL}/images/persona_icon/${d.persona_topimage_url}${this.iconSwitcher.bind(this, this.state.axis2, index)()}`} alt="" width="50" height="50" 
              style={{cursor: d.persona_number !== 0 ? cursor : "default"}}
            /><br/>
          </a>
          <div style={{whiteSpace: "nowrap", fontSize: "90%"}}>{d.persona_name}</div>
          {d.persona_number}人
          /{(Math.round(d.specialization_coef*100)/100).toFixed(2)}
        </Col>
      </OverlayTrigger>
    )
   }


 /**
  * TOPページにペルソナアイテムを表示する(左下)
  * @param {Object} d - カテゴリ別のぺルソナ情報
  * @param {Number} index - ぺルソナリストのindex
  * @param {String} cursor - ポインタのスタイル情報
  */
  displayTopPersonaItem3 = (d, index, cursor) => {
    let profile = d.persona_profile.replace(/(<([^>]+)>)/g,'')
    return(
      <OverlayTrigger key={d.persona_id} placement="bottom" target="outlineTooltip"
        overlay={<Tooltip>{profile.length > 100 ? (profile).slice(0,100)+"…" : profile}</Tooltip>}
      >
        <Col style={{textAlign: "center"}} xl={6} lg={6} xs={6} key={d.persona_id}>
          <a href={this.handleClick.bind(this, this.state.axis3, d.persona_id)()} target="_blank" rel="noopener noreferrer">
            <img 
              src={`${process.env.PUBLIC_URL}/images/persona_icon/${d.persona_topimage_url}${this.iconSwitcher.bind(this, this.state.axis3, index)()}`} alt="" width="50" height="50" 
              style={{cursor: d.persona_number !== 0 ? cursor : "default"}}
            /><br/>
          </a>
          <div style={{whiteSpace: "nowrap", fontSize: "90%"}}>{d.persona_name}</div>
          {d.persona_number}人
          /{(Math.round(d.specialization_coef*100)/100).toFixed(2)}
        </Col>
      </OverlayTrigger>
    )
   }


 /**
  * TOPページにペルソナアイテムを表示する(右下)
  * @param {Object} d - カテゴリ別のぺルソナ情報
  * @param {Number} index - ぺルソナリストのindex
  * @param {String} cursor - ポインタのスタイル情報
  */
  displayTopPersonaItem4 = (d, index, cursor) => {
    let profile = d.persona_profile.replace(/(<([^>]+)>)/g,'')
    return(
      <OverlayTrigger key={d.persona_id} placement="bottom" target="outlineTooltip"
        overlay={<Tooltip>{profile.length > 100 ? (profile).slice(0,100)+"…" : profile}</Tooltip>}
      >
        <Col style={{textAlign: "center"}} xl={6} lg={6} xs={6} key={d.persona_id}>
          <a href={this.handleClick.bind(this, this.state.axis4, d.persona_id)()} target="_blank" rel="noopener noreferrer">
            <img 
              src={`${process.env.PUBLIC_URL}/images/persona_icon/${d.persona_topimage_url}${this.iconSwitcher.bind(this, this.state.axis4, index)()}`} alt="" width="50" height="50" 
              style={{cursor: d.persona_number !== 0 ? cursor : "default"}}
            /><br/>
          </a>
          <div style={{whiteSpace: "nowrap", fontSize: "90%"}}>{d.persona_name}</div>
          {d.persona_number}人
          /{(Math.round(d.specialization_coef*100)/100).toFixed(2)}
        </Col>
      </OverlayTrigger>
    )
   }


  /**
   * ウィンドウに表示するペルソナスケルトン軸を切り替えるために、4軸以上にチェックを入れようとしていないか確認する
   * @param {number} personaName - ペルソナスケルトン名 
   * @param {number} personaId - ペルソナスケルトンid
   * @param {Array} dispList - 表示用ペルソナデータが入ったリスト
   * @param {number} index - 該当するペルソナスケルトンidが格納されている配列のindex
   */
  handleChange(personaName, personaId){
    let dispList = this.state.displayList;//id4つが格納
    if(document.getElementById(personaName).checked === true){
      //軸リストが4つ埋まってなかったら
      if(dispList.length < 4){
        //軸リストにIDを追加
        dispList.push(personaId);
      }
      else{
        alert("4つの軸が選択されています。");
        document.getElementById(personaName).checked = false;
      }
    }
    //チェックが外れた
    else if(document.getElementById(personaName).checked === false){
      //brandIdがresponseBrandの何番目に格納されているか調べる
      for(let i = 0; i < this.state.responsePersona.length; i++){
        //軸1だったチェックが外された
        if(this.state.responsePersona[i].persona_axis_id == personaId){
          //軸表示リストからIDを削除
          let index = dispList.indexOf(personaId);
          dispList.splice(index, 1);
        }
      }
      if(dispList.length !== 4){
        document.getElementById(personaName).checked = false;
        return false;
      }
    }
    this.setState({displayList: dispList});      
  }

  /**
   * 表示切替モーダルのチェック状態が、実際にウィンドウ表示されているペルソナスケルトン軸と合うよう設定する
   * @param {number} axisId - 軸id
   * @return {boolean} 真偽値を返す
   */
  defaultCheckedAxis(axisId){
    if(this.state.displayList[0] === axisId
       || this.state.displayList[1] === axisId
        || this.state.displayList[2] === axisId
         || this.state.displayList[3] === axisId){
      return true;
    }
    else{
      return false;
    }
  }

  /**
   * 特徴係数により変化するペルソナスケルトンアイコンの色3パターンを判断する
   * @param {number} axisIdndex - 軸インデックス
   * @param {number} personaIndex - ペルソナインデックス
   * @return {string} aがフルカラー、bが薄いカラー、cがグレーアウト
   */
  iconSwitcher(axisIdndex, personaIndex){
    if(this.state.responsePersona[axisIdndex].persona_list[personaIndex].specialization_coef > 0.4){
      return "a";
    }
    else if(this.state.responsePersona[axisIdndex].persona_list[personaIndex].specialization_coef <= 0.4
      && this.state.responsePersona[axisIdndex].persona_list[personaIndex].specialization_coef >= 0){
      return "b";
    }
    else if(this.state.responsePersona[axisIdndex].persona_list[personaIndex].specialization_coef < 0){
      return "c";
    }
  }    
  
  /**
   * 実際にウィンドウに表示する
   * @param {string} cursor - マウスカーソルの種類
   * @param {string} userAgen - ブラウザの種類
   * @return {JSX} トップページ表示用HTMLを返す
   */
  render() {
    //Internet Explorerかどうか判定
    let cursor = "pointer";
    // let userAgent = window.navigator.userAgent.toLowerCase();
    // if(userAgent.indexOf('trident') !== -1) {
    //   //押せないポインタにする
    //   cursor = "not-allowed";
    // }
    // else{
    //   cursor = "pointer";
    // }
  return (
    <div className="textPrint">
      {/* overviewモーダル */}
      <Modal
        show={this.props.modalState}
        onHide={this.closeModal} 
        dialogClassName="modal-80w"
      >
        <Modal.Header style={{textAlign: "center"}}>
          <h5 style={{marginBottom: 0}} className="mb-1"><b>OVERVIEW/表示切替</b></h5>
        </Modal.Header>
        <Modal.Body>
          <Row>{this.state.responsePersona.map((d, parentIndex) => this.displayOverviewPersonaCategory(d, parentIndex))}</Row>
          <Row className="d-flex" style={{justifyContent: "end"}}>
          {/* <div id="alert"></div> */}
          <Button
            variant="secondary"
            onClick={this.allUnCheckSwitchbox}
            style={{marginTop: 10, marginBottom: 10}}
            size="sm"
            className="ml-auto"
          >
            すべてのチェックを外す
          </Button>
          <Button
            variant="secondary"
            onClick={this.displayChange}
            // className="ml-auto"
            id="changeButton"
            size="sm"
            style={{ marginTop: 10, marginRight: 10, marginLeft: 10, marginBottom: 10 }}
          >
            チェックした項目を画面表示する
          </Button>
          <Button
            variant="secondary"
            onClick={this.closeModal}
            style={{marginTop: 10, marginBottom: 10}}
            size="sm"
            className="d-block mr-2 mr-4"
          >
            閉じる
          </Button>
        </Row>
      </Modal.Body>
    </Modal>


    {/* 絞り込みモーダル */}
    <Modal 
      show={this.props.filterModalIsOpen}
      onHide={this.closeModal}
      dialogClassName="modal-80w"
      >
      <Modal.Header style={{textAlign: "center"}}>
        <h5 style={{marginBottom: 0}}><b>ペルソナスケルトン絞り込み</b></h5>
      </Modal.Header>
      <Modal.Body>
        <Row>{this.state.responsePersona.map((d, parentIndex) => this.displayFilterPersonaCategory(d, parentIndex))}</Row>
        <Row className="d-flex" style={{right: "30px", bottom: "0px", justifyContent: "center"}}>
          <Button
            variant="secondary"
            onClick={this.setData}
            // onMouseUp={this.closeModal}
            style={{marginBottom: 10, marginRight: 10}}
            size="sm"
          >
            絞り込み
          </Button>
          <Button
            variant="secondary"
            onClick={this.closeModal}
            style={{marginBottom: 10}}
            size="sm"
            className="d-block"
          >
            閉じる
          </Button>
        </Row>
      </Modal.Body>
    </Modal>
    
{/* topページに表示する部分 */}
    <Container fluid className="print">
     <Row className="my-4">
      <Col xl={6} lg={6} xs={6}>
        <Table size="xs" key={`persona_top_table_0`}>
          <thead>
          <tr>
            <th colSpan="2" className="py-2 px-2" style={{borderTop: "0px", color: "#808080", textAlign: "center", verticalAlign:"middle", borderBottom: `solid 15px ${this.selectAxisColor.bind(this, this.state.responsePersona[this.state.axis1].persona_axis_id)()}` }}>
              {(() => {
                for(let i = 0; i < this.state.numberOfPersonaCategory; i++){
                  if(this.state.axis1 === i){
                    return this.state.responsePersona[i].persona_axis_name;
                  }
                  else{continue;}
                }
              })()}
            </th>
          </tr>
          </thead>
        </Table>
        <Row className="mt-2" key={`persona_top_0`}>
          {this.state.responsePersona[this.state.axis1].persona_list.map((d, index) => this.displayTopPersonaItem1(d, index, cursor))}
        </Row>
      </Col>
      <Col xl={6} lg={6} xs={6}>
        <Table size="xs" key={`persona_top_table_1`}>
          <thead>
            <tr>
              <th colSpan="2" className="py-2 px-2" style={{borderTop: "0px", color: "#808080", textAlign: "center", verticalAlign:"middle", borderBottom: `solid 15px ${this.selectAxisColor.bind(this, this.state.responsePersona[this.state.axis2].persona_axis_id)()}`}}>
              {(() => {
                for(let i = 0; i < this.state.numberOfPersonaCategory; i++){
                  if(this.state.axis2 === i){
                    return this.state.responsePersona[i].persona_axis_name;
                  }
                  else{continue;}
                }
              })()}
              </th>
          </tr>
          </thead>
        </Table>
        <Row className="mt-2" key={`persona_top_1`}>
          {this.state.responsePersona[this.state.axis2].persona_list.map((d, index) => this.displayTopPersonaItem2(d, index, cursor))}
        </Row>
      </Col> 
     </Row>
     <Row className="my-4">
      <Col xl={6} lg={6} xs={6}>
       <Table size="xs" key={"persona_top_table_2"}>
        <thead>
          <tr>
          <th colSpan="2" className="py-2 px-2" style={{borderTop: "0px", color: "#808080", textAlign: "center", verticalAlign:"middle", borderBottom: `solid 15px ${this.selectAxisColor.bind(this, this.state.responsePersona[this.state.axis3].persona_axis_id)()}`}}>
            {(() => {
              for(let i = 0; i < this.state.numberOfPersonaCategory; i++){
                if(this.state.axis3 === i){
                  return this.state.responsePersona[i].persona_axis_name;
                }
                else{continue;}
              }
            })()}
            </th>
          </tr>
        </thead>
      </Table>
        <Row className="mt-2" key={"persona_top_2"}>
          {this.state.responsePersona[this.state.axis3].persona_list.map((d, index) => this.displayTopPersonaItem3(d, index, cursor))}
        </Row>
      </Col>
      <Col xl={6} lg={6} xs={6}>
        <Table size="xs" key={`persona_top_table_3`}>
          <thead>
            <tr>
            <th colSpan="2" className="py-2 px-2" style={{borderTop: "0px", color: "#808080", textAlign: "center", verticalAlign:"middle", borderBottom: `solid 15px ${this.selectAxisColor.bind(this, this.state.responsePersona[this.state.axis4].persona_axis_id)()}`}}>
              {(() => {
                for(let i = 0; i < this.state.numberOfPersonaCategory; i++){
                  if(this.state.axis4 === i){
                    return this.state.responsePersona[i].persona_axis_name;
                  }
                  else{continue;}
                }
              })()}
            </th>
            </tr>
          </thead>
        </Table>
         <Row className="mt-2" key={`persona_top_3`}>
          {this.state.responsePersona[this.state.axis4].persona_list.map((d, index) => this.displayTopPersonaItem4(d, index, cursor))}
         </Row>
          </Col> 
        </Row>
      </Container>
    </div>
    );
  }
}
export default PersonaImage;