import React from "react";
import "./App.css";
import { Container } from "react-bootstrap";
import MultivariateAnalysis from "./MultivariateAnalysis";
import KeywordMap from "./KeywordMap";
import PositioningMap from "./PositioningMap";
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { getDS } from './DataStore';
import Api from './Api'
import {formatSurveyYear, formatSurvey,  formatSs} from "./common";
import Toast from 'react-bootstrap/Toast';

/**
 * マップページ描画クラス
 */
class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responseFreeform: "",
      labelImage: "",
      nodeList: "",
      linkList: "",
      pmapPersona: [],
      index: 0,
      graphData: "",
      prefImage: "",
      // survey: 201912,
      id: "",
      listOfFilterInTop: "",
      // dispSurvey: 2019,
    };
    this.setImage = this.setImage.bind(this);
  }

  /**
   * (React組み込み関数)初期画面表示のためのデータを整理する
   * ※Top.jsにUNSAFE_componentWillMount()の公式URLを記載
   * @param {string} jwt - 認証情報
   * @param {string} hash - URLの#以降を切り取りデコードした文字列
   * @param {string} getData - hashから圧縮文字列の展開キーを切り取った文字列
   * @param {string} index - 画面初期表示時に開いておくタブのindex
   * @param {Array} pmap - ポジショニングマップサムネイルのリスト
   */
  UNSAFE_componentWillMount() {
    let self = this;
    let hash = decodeURIComponent(window.location.hash);
    let getData = hash.split("&filter=");
    let index;    //Topで何かしらの絞り込みがされた
    
    if (getData[1] !== "") {
      index = getData[0].split("index=")[1];
      getDS([getData[1]])
        .then(response_filter => {
          // 性別情報を取り出す
          const sexId = window.location.hash.split("s=")[1];
          const sex = Number(sexId.slice(0, 1));
          sessionStorage.setItem("se", sex);
          sessionStorage.setItem("sy", localStorage.getItem("sy"));
          // 調査データ判定
          let survey;
          let query = JSON.parse(response_filter[getData[1]]);
          let assumed_surveys = JSON.parse(localStorage.getItem("sy")).filter((sv)=> {
            if(sv.name.indexOf(query.switch_survey) !== -1 && Number(sv.sex) === sex){
              return sv
            }
          });
          survey = assumed_surveys.slice(-1)[0].name.toString();
          sessionStorage.setItem("ss", formatSs(survey, sex));
          localStorage.setItem("sv", survey);

          this.setState({
            index: parseInt(index),
            graphData: JSON.parse(response_filter[getData[1]])
          });

          // ブラウザタブタイトルを変更する
          let s = formatSurveyYear();
          let se = Number(JSON.parse(sessionStorage.getItem("ss")).sex)? "男性" : "女性";
          document.title = `${s}${se}|MAP`;

          //自由記述情報を取得
          new Api().post('/api/v1/filter', {"filters": JSON.parse(response_filter[getData[1]]), "survey": formatSurvey(), "sex": Number(sessionStorage.getItem("se")) }, 
          response_freeform => {
            self.setState({
              responseFreeform: response_freeform.data.freeform_list,
              // survey: response_freeform.data.purchasing_behavior_list[0].survey_name,
              // dispSurvey: response_freeform.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4),
          });
            //ポジショニングマップサムネイルを取得
            new Api().post("/api/v1/pmaps_info", {"survey": formatSurvey(), "sex": Number(sessionStorage.getItem("se"))}, 
            response_thumbnail => {
              let pmap = [];
              let male = Number(sessionStorage.getItem("se")) === 1? "m" : ""; 
              for (let i = 0; i < response_thumbnail.data.maps.length; i++) {
                let zeroPaddedId = ('00' + response_thumbnail.data.maps[i].graph_id).slice(-2);
                pmap.push(
                  {
                    "title": response_thumbnail.data.maps[i].graph_label,
                    "image_src": `${process.env.PUBLIC_URL}/images/pmap_filter/pmap_${response_freeform.data.purchasing_behavior_list[0].survey_name}${male}_${zeroPaddedId}_00.png`,
                    "id": zeroPaddedId
                  }
                );
              }
              self.setState({ pmapPersona: pmap });
              for (let i = 0; i < pmap.length; i++) {
                if (pmap[i].image_src === `${process.env.PUBLIC_URL}/images/pmap_filter/pmap_${response_freeform.data.purchasing_behavior_list[0].survey_name}${male}_${pmap[i].id}_00.png`) {
                  new Api().post(
                    "/api/v1/pmap_with_filter",
                    {filters: JSON.parse(response_filter[getData[1]]), "graph_id": pmap[i].id, "height": 250, "width": 350, "disp_label": false, "survey": formatSurvey(), "sex": Number(sessionStorage.getItem("se"))},
                    response_personaThumbnail => {
                      pmap[i].image_src = response_personaThumbnail.data.image_src;
                      self.setState({ pmapPersona: pmap })
                    }
                  );
                }
                else { continue; }
              }
            })
          });
          //KW情報を取得
          new Api().post('/api/v1/kwmap', {"filters": JSON.parse(response_filter[getData[1]]), "survey": formatSurvey()}, 
          response_keyword => {
            // ノード情報とリンク情報をKeywordMap.jsに渡す
            self.setState({
              nodeList: response_keyword.data.nodes,
              linkList: response_keyword.data.links
            });
          });
          // プリファレンスマップ情報を取得
          new Api().post('/api/v1/pref', {"filters": JSON.parse(response_filter[getData[1]]), "image_ratio": 0.8, "survey": formatSurvey(), "sex": Number(sessionStorage.getItem("se"))}, 
          response_pref => {
            self.setState({ prefImage: response_pref.data.image_src });
          });
        })
        .catch((error) => {
          console.log("error");
          localStorage.removeItem("ci");
          localStorage.removeItem("tr");
          localStorage.removeItem("it");
          localStorage.removeItem("sy");
          localStorage.removeItem("ss");
          localStorage.removeItem("sv");
          localStorage.removeItem("se");
        })
    }
  }

  /**
   * サムネイクリック時に表示する高画質なポジショニングマップを取得する
   * @param {string} id - ポジショニングマップのid
   * @param {string} jwt - 認証情報
   * @param {number} zeroPaddedBrand - ゼロパディング済のポジショニングマップid
   */
  setImage(id) {
    const self = this;
    new Api().post('/api/v1/pmap_with_filter', 
    {filters: this.state.graphData, "graph_id": id, "height": 700, "width": 1000, "disp_label": true, "survey": formatSurvey(), "sex": Number(sessionStorage.getItem("se"))},
    response => {
      self.setState({
        labelImage: response.data.image_src,
        id: id
      });
    });
  }

  /**
   * 実際にウィンドウに表示する
   * @param {boolean} disabledKW - KWマップタブを開けるかどうかの真偽値を返す
   * @param {boolean} disabledPosi - ポジショニングマップタブを開けるかどうかの真偽値を返す
   * @param {boolean} disabledMulti - プリファレンスマップタブを開けるかどうかの真偽値を返す
   * @return {JSX} マップページを返す
   */
  render() {
    if (this.state.nodeList === "" || this.state.linkList === "" || this.state.graphData === "" || this.state.responseFreeform === "") {
      return (
        <div className="d-flex justify-content-center mt-4 pt-4">
          <div className="spinner-border text-secondary" style={{ width: "5rem", height: "5rem" }} role="status">
            <span className="sr-only">Loading...</span>
          </div>
            <Toast style={{ position: 'absolute', top: 10, margin: "auto", color: "#4169e1", width: "600px", height: "100px", backgroundColor: "#e0ffff" }}>
              <Toast.Body>リクエストを処理しています。この処理には時間が掛かる場合があります。しばらくお待ちください。</Toast.Body>
            </Toast>
        </div>
      );
    }
    let disabledKW = false;
    let disabledPosi = false;
    let disabledMulti = false;
    if (this.state.nodeList === "" || this.state.linkList === "" || this.state.responseFreeform === "") {
      disabledKW = true;
    }
    else if (this.state.graphData === "") {
      disabledMulti = true;
    }
    let surveys = JSON.parse(localStorage.getItem("sy"));
    return (
      <Container fluid style={{ backgroundColor: "#f5f5f5" }} className="print">
        <div><b style={{fontSize: "150%", position: "absolute", right: 20}}>
          表示データ：{(() => {
            const sex = Number(sessionStorage.getItem('se')) === 0 ? "女性" : "男性";
            for(let i = 0; i < surveys.length; i++){
              if(surveys[i].name === formatSurvey()){
                if(formatSurveyYear() === "2022"){
                  return `${sex} ／ ${surveys[i].label}`;
                }
                else if(formatSurveyYear() === "2023"){
                  return `${sex} ／ ${surveys[i].label}`;
                }
                else{
                  return surveys[i].label;
                }
              }
            }
          })()}
        </b></div>
        <Tabs defaultIndex={this.state.index}>
          <TabList>
            <Tab disabled={disabledPosi}>ポジショニングマップ</Tab>
            <Tab
              // disabled={disabledMulti}
              disabled={formatSurveyYear() === "2023" ?  true:disabledMulti}
              style={formatSurveyYear() === "2023" ? {display:"none"}:{display:"inline"}}
            >プリファレンスマップ</Tab>
            <Tab disabled={disabledKW}>KWマップ</Tab>
          </TabList>
          {/* ポジショニングマップ */}
          <TabPanel style={{ backgroundColor: "#f5f5f5" }}>
            <PositioningMap
              pmapPersona={this.state.pmapPersona}
              setImage={this.setImage}
              // setImage={this.setImage.bind(this)}
              labelImage={this.state.labelImage}
              id={this.state.id}
            />
          </TabPanel>
          {/* プリファレンスマップ */}
          <TabPanel style={{ backgroundColor: "#f5f5f5", height: "780px" }}>
            <div style={formatSurveyYear() === "2023" ? {display:"none"}:{display:"block"}}>
              <MultivariateAnalysis prefImage={this.state.prefImage} />
            </div>
          </TabPanel>
          {/* KWマップ */}
          <TabPanel style={{ backgroundColor: "#f5f5f5", height: "800px" }}>
            <KeywordMap
              responseFreeform={this.state.responseFreeform}
              nodes={this.state.nodeList}
              links={this.state.linkList}
            />
          </TabPanel>
        </Tabs>
      </Container>
    )
  }
}
export default Map;