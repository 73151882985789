import React from "react";
import { Nav, Navbar, Button, Modal } from "react-bootstrap";
import logo from "./images/persona_cube.png";
import save from "./images/icons/save.png";
import printer from "./images/icons/printer.png";
import "./App.css";
import { Col } from "react-bootstrap";
import search from "./images/icons/search.png";
import question from "./images/icons/question.png";
import mypage from "./images/icons/mypage.png";
import logout from "./images/icons/logout.png";
import help2019 from "./helpPdf/ペルソナキューブ操作マニュアルver4.0.pdf";
import help2020 from "./helpPdf/ペルソナキューブ操作マニュアルver4.0.pdf";
import help2021 from "./helpPdf/ペルソナキューブ操作マニュアル ver4.1.pdf";
import help2022 from "./helpPdf/ペルソナキューブ操作マニュアル ver4.2.pdf";
import help2023 from "./helpPdf/ペルソナキューブ操作マニュアル ver4.3.pdf";
import { getDS } from "./DataStore";
import Api from "./Api";
import {formatSurvey} from "./common";

// Modal.setAppElement("#root");
/**
 * ナビバー描画クラス
 */
class Navi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveModalIsOpen: false,
      filterState: "",
      filterPersonaState: "",
      displayFilterState: "",
      displaySearchResult: "",
      forceUpdate: "",
      searchWord: "",
      survey: "",
    };
    this.saveData = this.saveData.bind(this);
    this.deletefilter = this.deletefilter.bind(this);
    this.reFilter = this.reFilter.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.basicStateGenerator = this.basicStateGenerator.bind(this);
    this.basicChildrenGeneratorForFilter = this.basicChildrenGeneratorForFilter.bind(this);
    this.basicChildrenGeneratorForSearch = this.basicChildrenGeneratorForSearch.bind(this);
    this.lifestyleStateGenerator = this.lifestyleStateGenerator.bind(this);
    this.lifestyleChildrenGenerator = this.lifestyleChildrenGeneratorForFilter.bind(this);
    this.lifestyleChildrenGenerator = this.lifestyleChildrenGeneratorForSearch.bind(this);
    this.personaStateGenerator = this.personaStateGenerator.bind(this);
    this.personaChildrenGenerator = this.personaChildrenGeneratorForFilter.bind(this);
    this.personaChildrenGenerator = this.personaChildrenGeneratorForSearch.bind(this);
    this.brandStateGenerator = this.brandStateGenerator.bind(this);
    this.brandChildrenGenerator = this.brandChildrenGeneratorForFilter.bind(this);
    this.brandChildrenGenerator = this.brandChildrenGeneratorForSearch.bind(this);
    this.checkHit = this.checkHit.bind(this);
    this.display = this.display.bind(this);
    this.openHelp = this.openHelp.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getData = this.getData.bind(this);
    this.forceUpdatePage = this.forceUpdatePage.bind(this);
  };

  /**
   * (React組み込み関数)初期画面表示のためのデータを整理する
   * ※Top.jsにUNSAFE_componentWillMount()の公式URLを記載
   * @param {string} filter - JSONしたリクエスト
   * @param {string} hash - URLの#以降を切り取りデコードした文字列
   * @param {string} pageId - URLからpage=移行を切り取ったもの
   * @param {string} page - 表示中ページid
   * @param {string} filterJson - 絞り込み条件のJSON文字列
   */
  UNSAFE_componentWillMount() {
    let pageId = window.location.hash.split("page=")[1];
    //トップページの絞り込み条件保存モーダル作成用
    if (pageId === undefined || pageId === null) {
      const filter_state = {
        "basic_list": [
          {
            "basic_id": 0,
            "basic_name": "基本属性",
            "basic_filter_list": [
              {
                "basic_filter_id": 0,
                "basic_filter_selected": 0,
                "basic_filter_name": "10代"
              }
            ]
          }
        ],
        "purchasing_behavior_list": [
          {
            "purchasing_behavior_id": 0,
            "purchasing_behavior_name": "購買行動",
            "purchasing_behavior_contents_filter_list": [
              {
                "purchasing_behavior_contents_filter_id": 0,
                "purchasing_behavior_item_filter_selected": 0,
                "purchasing_behavior_contents_filter_name": "InstagramなどSNSを見て"
              }
            ]
          }
        ],
        "persona_list": [
          {
            "axis_id": 0,
            "axis_name": "ペルソナスケルトン",
            "persona_filter_list": [
              {
                "persona_filter_id": 0,
                "persona_filter_selected": 0,
                "persona_filter_name": "洗練"
              }
            ]
          }
        ],
        "lifestyle_list": [
          {
            "lifestyle_id": 0,
            "lifestyle_name": "価値観",
            "lifestyle_filter_list": [
              {
                "lifestyle_filter_id": 0,
                "lifestyle_filter_selected": 0,
                "lifestyle_filter_name": "価値観"
              }
            ]
          }
        ]
      };
      this.setState({
        filterState: filter_state,//全部
        displayFilterState: filter_state,//表示している最新状態
        filterPersonaState: filter_state//表示している最新状態
      });
    }
    else {
      let page = pageId.slice(0, 1);
      if (page === "1") {//詳細ページの絞り込み条件保存モーダル作成用
        let hash = decodeURIComponent(window.location.hash);
        let filter = hash.split("filter=")[1];
        getDS([filter])
        .then(response => {
          this.setState({
            filterState: JSON.parse(response[filter]),//全部
            displayFilterState: JSON.parse(response[filter]),//表示している最新状態
            filterPersonaState: JSON.parse(response[filter])//表示している最新状態
          });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            alert("認証に失敗しました。ログイン画面に戻ります。\n操作途中のデータは保存されません。");
            window.location.href = "./#/login";
            console.log("error");
            localStorage.removeItem("ci");
            localStorage.removeItem("it");
          }
          else {
            console.log("error");
            localStorage.removeItem("ci");
            localStorage.removeItem("it");
          }
        })
      }
      else if (page === "2") {//マップページの絞り込み条件保存モーダル作成用
        let hash = decodeURIComponent(window.location.hash);
        let filter = hash.split("filter=")[1];
        getDS([filter])
        .then(response => {
          this.setState({
            filterState: JSON.parse(response[filter]),//全部
            displayFilterState: JSON.parse(response[filter]),//表示している最新状態
          });
          // ナビボタンを表示させるために強制レンダリング
          this.forceUpdate();
        })
        .catch((error) => {
          if (error.response.status === 401) {
            alert("認証に失敗しました。ログイン画面に戻ります。\n操作途中のデータは保存されません。");
            window.location.href = "./#/login";
            console.log("error");
            localStorage.removeItem("ci");
            localStorage.removeItem("it");
          }
          else {
            console.log("error");
            localStorage.removeItem("ci");
            localStorage.removeItem("it");
          }
        })
      }
      else if (page === "") {//トップページ
        let hash = decodeURIComponent(window.location.hash);
        let filter = hash.split("filter=")[1];
        // URL解凍
        let filterJson;
        getDS([filter])
          .then(response => {
            filterJson = response[filter];
          })
          .catch((error) => {
            if (error.response.status === 401) {
              alert("認証に失敗しました。ログイン画面に戻ります。\n操作途中のデータは保存されません。");
              window.location.href = "./#/login";
              console.log("error");
              localStorage.removeItem("ci");
              localStorage.removeItem("it");
            }
            else {
              console.log("error");
              localStorage.removeItem("ci");
              localStorage.removeItem("it");
            }
          })
        filter = JSON.parse(filterJson);
        this.setState({
          filterPersonaState: filter,
          displayFilterState: filter
        });
      }
    }
  }

  /**
   * (React組み込み関数)絞り込み条件の変更有無が分かる
   * https://ja.reactjs.org/docs/react-component.html#shouldcomponentupdate
   * @param {Array} nextProps - 次に更新される絞り込み結果
   * @param {string} pageId - 表示中ページid
   * @return {boolean} 変更有無
   */
  shouldComponentUpdate(nextProps) {
    let pageId = window.location.hash.split("page=")[1];
    if (pageId === undefined || pageId === null) {
      if (this.state.filterState === nextProps.listOfFilterInTop) {
        return false;
      }
      else if (this.state.filterState !== nextProps.listOfFilterInTop) {
        return true;
      }
    }
    else {
      if (this.state.filterPersonaState === nextProps.listOfFilterInPersona) {
        return false;
      }
      else if (this.state.filterPersonaState !== nextProps.listOfFilterInPersona) {
        return true;
      }
    }
  }

  /**
   * (React組み込み関数)絞り込み結果が更新された時、画面表示用にデータを整理する
   * ※Top.jsにcomponentDidUpdate()の公式URLを記載
   * @param {object} prevProps - 絞り込み条件
   * @param {object} filterData - トップページで絞り込んだfilterAPIのリクエスト
   * @param {object} personaFilterData - ペルソナ詳細ページで絞り込んだfilterAPIのリクエスト
   */
  componentDidUpdate(prevProps) {
    //Topで絞込/表示切替が行われた
    if (prevProps.listOfFilterInTop !== this.props.listOfFilterInTop) {
      let filterData = JSON.parse(JSON.stringify(this.props.listOfFilterInTop));
      this.getData.bind(this, filterData)();
    }
    //Detailで絞込が行われた
    else if (prevProps.listOfFilterInPersona !== this.props.listOfFilterInPersona) {
      let personaFilterData = JSON.parse(JSON.stringify(this.props.listOfFilterInPersona));
      this.getData.bind(this, personaFilterData)();
    }
  }

  /**
   * 条件一覧モーダルに表示する絞り込み条件を更新する
   * @param {Array} filterData - 絞り込み条件
   * @param {string} url - URLの#以降を切り取った文字列
   */
  getData(filterData) {
    let url = window.location.hash.split("/")[1];
    //トップページでの絞り込み
    if (url === "") {
      this.setState({
        filterState: filterData,//全部
        displayFilterState: filterData,//表示している最新状態
      });
    }
    //マイページから復元したトップページの絞り込み
    else if (url.charAt(0) === "?") {
      this.setState({
        filterState: filterData,//全部
        displayFilterState: filterData,//表示している最新状態
      });
    }
    //ペルソナ詳細ページでの絞り込み
    else if (url.charAt(0) === "p") {
      this.setState({
        filterPersonaState: filterData,//全部
        displayFilterState: filterData,//表示している最新状態
      });
    }
  }

  /**
   * ヘルプページへ遷移する
   */
  openHelp() {
    // TODO fixme 調査名が含まれているヘルプを表示する
    if(formatSurvey().indexOf("201909") !== -1){  
      window.open(help2019);
    }
    else if(formatSurvey().indexOf("202011") !== -1){
      window.open(help2020);
    }
    else if(formatSurvey().indexOf("202111") !== -1){
      window.open(help2021);
    }
    else if(formatSurvey().indexOf("202211") !== -1){
      window.open(help2022);
    }
    else if(formatSurvey().indexOf("202306") !== -1){
      window.open(help2023);
    }
  }

  /**
   * 表示用メニューボタンを更新する
   */
  forceUpdatePage(){
    this.setState({forceUpdate: ""});
  }

  /**
   * 各種ページへ遷移する
   * @param {string} page - ページの種類
   * @param {string} origin - 取得したURL
   */
  handleClick(page) {
    let origin = window.location.origin;
    if (page === "mypage") {
      window.open(`${origin}/#/mypage`);
    }
    else if (page === "top") {
      window.open(`${origin}/#/`);
    }
    else if (page === "logout") {
      localStorage.removeItem("ci");
      localStorage.removeItem("tr");
      localStorage.removeItem("it");
      localStorage.removeItem("sy");
      localStorage.removeItem("ss");
      localStorage.removeItem("sv");
      sessionStorage.removeItem("ss");
      localStorage.removeItem("se");
      sessionStorage.removeItem("se");
      window.location.href = "./#/login";
    }
  }

  /**
   * 各種モーダルを開く
   * @param {string} type - 開くモーダルの種類
   */
  openModal(type) {
    if (type === "save") {
      this.setState({ saveModalIsOpen: true });
      this.forceUpdate();
    }
    else if (type === "filter") {
      this.setState({ filterModalIsOpen: true });
    }
    else if (type === "search") {
      this.setState({ searchModalIsOpen: true });
    }
  }

  /**
   * 各種モーダルを閉じる
   * @param {string} url - URLの#以降を切り取った文字列
   */
  closeModal() {
    let url = window.location.hash.split("/")[1];
    //詳細ページ
    if(url.indexOf("persona-detail") !== -1) {
      this.setState({ displayFilterState: this.state.filterPersonaState });
    }
    //MAPページ
    else if(url.indexOf("map") !== -1) {
      this.setState({ displayFilterState: this.state.filterState });
      this.forceUpdate();
    }
    //topページ
    // "/"の時とクエリがくっついている時があるから
    else{
      this.setState({ displayFilterState: this.state.filterState });
    }
    this.setState({
      saveModalIsOpen: false,
      filterModalIsOpen: false,
      searchModalIsOpen: false,  
      searchWord: "",
      displaySearchResult: "",
    });
  }

  /**
   * App.jsの絞込条件を更新する
   * @param {string} url - URLの#以降を切り取った文字列
   */
  reFilter(type) {
    let url = window.location.hash.split("/persona-detail")[1];
    //topページ
    if (url === "" || url === undefined || url === null) {
      // 絞り込み条件保存からの絞り込みなのかパラメータ検索の絞り込みなのか判別する
      if(type === "search"){
        this.props.setReFilterAllState(this.state.displaySearchResult);
      }
      else{
        this.props.setReFilterAllState(this.state.displayFilterState);
      }
      this.closeModal.bind(this)();
    }
    else {
      //詳細ページ
      this.props.setRePersonaFilterAllState(this.state.displayFilterState);
      this.closeModal.bind(this)();
    }
  }

  /**
   * 各大カテゴリ内の項目がヒットしたかどうかを大カテゴリごとに返す
   * @param {object} data - 調査データ全部
   */
  checkHit(data) {
    let checkList = data;
    let existBasic = false;
    let existLifestyle = false;
    let existPersona = false;
    let existBrand = false;

    // basic
    for (let i = 0; i < checkList.basic_list.length; i++) {
      for (let j = 0; j < checkList.basic_list[i].basic_filter_list.length; j++) {
        if (checkList.basic_list[i].basic_filter_list[j].search_result_hit === 0) {
          continue;
        }
        else {
          existBasic = true;
          break;
        }
      }
    }
    // lifestyle
    for (let i = 0; i < checkList.lifestyle_list.length; i++) {
      for (let j = 0; j < checkList.lifestyle_list[i].lifestyle_filter_list.length; j++) {
        if (checkList.lifestyle_list[i].lifestyle_filter_list[j].search_result_hit === 0) {
          continue;
        }
        else {
          existLifestyle = true;
          break;
        }
      }
    }
    // persona
    for (let i = 0; i < checkList.persona_list.length; i++) {
      for (let j = 0; j < checkList.persona_list[i].persona_filter_list.length; j++) {
        if (checkList.persona_list[i].persona_filter_list[j].search_result_hit === 0) {
          continue;
        }
        else {
          existPersona = true;
          break;
        }
      }
    }
    // purchasing_behavior
    for (let i = 0; i < checkList.purchasing_behavior_list.length; i++) {
      for (let j = 0; j < checkList.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list.length; j++) {
        if (checkList.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list[j].search_result_hit === 0) {
          continue;
        }
        else {
          existBrand = true;
          break;
        }
      }
    }
    return {existBasic, existLifestyle, existPersona, existBrand}
  }


  /**
   * 保存条件ブロックを作成する(基本属性)
   * @param {object} data - 基本属性ブロック情報
   * @param {boolean} isFilter - 絞り込み条件保存かどうか
   * @param {boolean} isMap - MAPページ(絞り込み条件削除ができる)かどうか
   * @return {JSX} 基本属性ブロック名を表示するHTMLを返す
   */
  basicStateGenerator = (data, isFilter, isMap) => {
    if (data.basic_filter_list.length !== 0) {
      for (let i = 0; i < data.basic_filter_list.length; i++) {
        if (isFilter || isMap? data.basic_filter_list[i].basic_filter_selected === 0 : data.basic_filter_list[i].search_result_hit === 0) {
          continue;
        }
        else {
          return (
            <div key={`basic_${data.basic_id}`}>
              <b>{data.basic_name}</b>
              <div>{data.basic_filter_list.map(d => 
                isFilter || isMap?
                this.basicChildrenGeneratorForFilter(d, data.basic_id, isMap)
                :this.basicChildrenGeneratorForSearch(d, data.basic_id)
              )}</div>
            </div>
          );
        }
      }
    }
  }

  /**
   * 保存条件項目を作成する(基本属性)
   * @param {object} data - 基本属性項目情報
   * @param {number} id - 基本属性ブロックid
   * @param {boolean} isMap - MAPページ(絞り込み条件削除ができる)かどうか
   * @return {JSX} 基本属性項目名を表示するHTMLを返す
   */
  basicChildrenGeneratorForFilter = (data, id, isMap)=> {
    if (data.basic_filter_selected === 1) {
      let basicId = "navi_1_" + data.basic_filter_id;
      return (
        <div
          key={`basic_contents_${id}_${data.basic_filter_id}`}
          style={{ cursor:isMap? "default" : "pointer", display: "inline-block"}}
          className="filter"
          onClick={this.deletefilter.bind(this, data.basic_filter_id, id, 1)}
          id={basicId}
        >
          {data.basic_filter_name}
          <svg style={{display:isMap? 'none' : 'inline'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="9" y1="3" x2="3" y2="9"></line><line x1="3" y1="3" x2="9" y2="9"></line></svg>
        </div>
      );
    }
  }

  
  /**
   * パラメータ検索結果を作成する(基本属性)
   * @param {object} data - 基本属性項目情報
   * @param {number} id - 基本属性ブロックid
   * @return {JSX} 基本属性項目名を表示するHTMLを返す
   */
  basicChildrenGeneratorForSearch = (data, id) => {
    if (data.search_result_hit === 1) {
      return (
        <label key={`basic_contents_${id}_${data.basic_filter_id}`} style={{display: 'block'}} className='mr-3'>
          <input 
            type="checkbox"
            id={data.basic_filter_name}
            onChange={this.addSearch.bind(this, data.basic_filter_id, id, 1)}
              checked={data.basic_filter_selected === 1}
            />
          {data.basic_filter_name}
        </label>
      );
    }
  }

  /**
   * 保存条件ブロックを作成する(ライフスタイル)
   * @param {object} data - ライフスタイルブロック情報
   * @param {boolean} isFilter - 絞り込み条件保存かどうか
   * @param {boolean} isMap - MAPページ(絞り込み条件削除ができる)かどうか
   * @return {JSX} ライフスタイルブロック名を表示するHTMLを返す
   */
  lifestyleStateGenerator = (data, isFilter, isMap) => {
    if (data.lifestyle_filter_list.length !== 0) {
      for (let i = 0; i < data.lifestyle_filter_list.length; i++) {
        if (isFilter || isMap? data.lifestyle_filter_list[i].lifestyle_filter_selected === 0 : data.lifestyle_filter_list[i].search_result_hit === 0) {
          continue;
        }
        else {
          return (
            <div key={`life_${data.lifestyle_id}`}>
              <b>{data.lifestyle_name}</b>
              <div>{data.lifestyle_filter_list.map(d => 
                isFilter || isMap?
                this.lifestyleChildrenGeneratorForFilter(d, data.lifestyle_id, isMap)
                :this.lifestyleChildrenGeneratorForSearch(d, data.lifestyle_id)
              )}</div>
            </div>
          );
        }
      }
    }
  }

  /**
   * 保存条件項目を作成する(ライフスタイル)
   * @param {object} data - ライフスタイル項目情報
   * @param {number} id - ライフスタイルブロックid
   * @return {JSX} ライフスタイル項目名を表示するHTMLを返す
   */
  lifestyleChildrenGeneratorForFilter = (data, id, isMap) => {
    if (data.lifestyle_filter_selected === 1) {
      let lifeId = "navi_2_" + data.lifestyle_filter_id;
      return (
        <div
          key={`life_contents_${id}_${data.lifestyle_filter_id}`}
          style={{ cursor:isMap? "default" : "pointer", display: "inline-block" }}
          className="filter"
          onClick={this.deletefilter.bind(this, data.lifestyle_filter_id, id, 2)}
          id={lifeId}
        >
          {data.lifestyle_filter_name}
          <svg style={{display:isMap? 'none' : 'inline'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="9" y1="3" x2="3" y2="9"></line><line x1="3" y1="3" x2="9" y2="9"></line></svg>
        </div>
      );
    }
  }

  /**
   * パラメータ検索結果を作成する(ライフスタイル)
   * @param {object} data - ライフスタイル項目情報
   * @param {number} id - ライフスタイルブロックid
   * @param {boolean} isMap - MAPページ(絞り込み条件削除ができる)かどうか
   * @return {JSX} ライフスタイル項目名を表示するHTMLを返す
   */
  lifestyleChildrenGeneratorForSearch = (data, id) => {
    if (data.search_result_hit === 1) {
      return (
        <label key={`life_contents_${id}_${data.lifestyle_filter_id}`} style={{display: 'block'}} className='mr-3'>
          <input 
            type="checkbox"
            id={data.lifestyle_filter_name}
            onChange={this.addSearch.bind(this, data.lifestyle_filter_id, id, 2)}
            checked={data.lifestyle_filter_selected === 1}
          />
          {data.lifestyle_filter_name}
        </label>
      );
    }
  }

  /**
   * 保存条件ブロックを作成する(ペルソナスケルトン)
   * @param {object} data - ペルソナスケルトンブロック情報
   * @param {boolean} isFilter - 絞り込み条件保存かどうか
   * @param {boolean} isMap - MAPページ(絞り込み条件削除ができる)かどうか
   * @return {JSX} ペルソナスケルトンブロック名を表示するHTMLを返す
   */
  personaStateGenerator = (data, isFilter, isMap) => {
    if (data.persona_filter_list.length !== 0) {
      for (let i = 0; i < data.persona_filter_list.length; i++) {
        if (isFilter || isMap? data.persona_filter_list[i].persona_filter_selected === 0 : data.persona_filter_list[i].search_result_hit === 0) {
          continue;
        }
        else {
          return (
            <div key={`persona_${data.axis_id}`}>
              <b>{data.axis_name}</b>
              <div>{data.persona_filter_list.map(d => 
                isFilter || isMap?
                this.personaChildrenGeneratorForFilter(d, data.axis_id, isMap)
                :this.personaChildrenGeneratorForSearch(d, data.axis_id)
              )}</div>
            </div>
          );
        }
      }
    }
  }

  /**
   * 保存条件項目を作成する(ペルソナスケルトン)
   * @param {object} data - ペルソナスケルトン項目情報
   * @param {number} id - ペルソナスケルトンブロックid
   * @param {boolean} isMap - MAPページ(絞り込み条件削除ができる)かどうか
   * @return {JSX} ペルソナスケルトン項目名を表示するHTMLを返す
   */
  personaChildrenGeneratorForFilter = (data, id, isMap)=> {
    if (data.persona_filter_selected === 1) {
      let personaId = "navi_3_" + data.persona_filter_id;
      return (
        <div
          key={`persona_contents_${id}_${data.persona_filter_id}`}
          style={{ cursor:isMap? "default" : "pointer", display: "inline-block" }}
          className="filter"
          onClick={this.deletefilter.bind(this, data.persona_filter_id, id, 3)}
          id={personaId}
        >
          {data.persona_filter_name}
          <svg style={{display:isMap? 'none' : 'inline'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="9" y1="3" x2="3" y2="9"></line><line x1="3" y1="3" x2="9" y2="9"></line></svg>
        </div>
      );
    }
  }

  /**
   * パラメータ検索結果を作成する(ペルソナスケルトン)
   * @param {object} data - ペルソナスケルトン項目情報
   * @param {number} id - ペルソナスケルトンブロックid
   * @return {JSX} ペルソナスケルトン項目名を表示するHTMLを返す
   */
  personaChildrenGeneratorForSearch = (data, id) => {
    if (data.search_result_hit === 1) {
      return (
        <label key={`persona_contents_${id}_${data.persona_filter_id}`} style={{display: 'block'}}>
          <input 
            type="checkbox"
            id={data.persona_filter_name}
            onChange={this.addSearch.bind(this, data.persona_filter_id, id, 3)}
            checked={data.persona_filter_selected === 1}
          />
          {data.persona_filter_name}
        </label>
      );
    }
  }
  
  /**
   * 保存条件ブロックを作成する(購買行動)
   * @param {object} data - 購買行動ブロック情報
   * @param {boolean} isFilter - 絞り込み条件保存かどうか
   * @param {boolean} isMap - MAPページ(絞り込み条件削除ができる)かどうか
   * @return {JSX} 購買行動ブロック名を表示するHTMLを返す
   */
  brandStateGenerator = (data, isFilter, isMap) => {
    if (data.purchasing_behavior_contents_filter_list.length !== 0) {
      for (let i = 0; i < data.purchasing_behavior_contents_filter_list.length; i++) {
        if (isFilter || isMap? data.purchasing_behavior_contents_filter_list[i].purchasing_behavior_item_filter_selected === 0 : data.purchasing_behavior_contents_filter_list[i].search_result_hit === 0) {
          continue;
        }
        else {
          return (
            <div key={`brand_${data.purchasing_behavior_id}`}>
              <b>{data.purchasing_behavior_name}</b>
              <div>{data.purchasing_behavior_contents_filter_list.map(d => 
                isFilter || isMap?
                this.brandChildrenGeneratorForFilter(d, data.purchasing_behavior_id, isMap)
                :this.brandChildrenGeneratorForSearch(d, data.purchasing_behavior_id)
              )}</div>
            </div>
          );
        }
      }
    }
  }

  /**
   * 保存条件項目を作成する(購買行動)
   * @param {object} data - 購買行動項目情報
   * @param {number} id - 購買行動ブロックid
   * @param {boolean} isMap - MAPページ(絞り込み条件削除ができる)かどうか
   * @return {JSX} 購買行動ブロック名を表示するHTMLを返す
   */
  brandChildrenGeneratorForFilter = (data, id, isMap) => {
    if (data.purchasing_behavior_item_filter_selected === 1) {
      let brandId = "navi_4_" + data.purchasing_behavior_contents_filter_id;
      return (
        <div
          key={`brand_contents_${id}_${data.purchasing_behavior_contents_filter_id}`}
          style={{ cursor:isMap? "default" : "pointer", display: "inline-block" }}
          className="filter"
          onClick={this.deletefilter.bind(this, data.purchasing_behavior_contents_filter_id, id, 4)}
          id={brandId}
        >
          {data.purchasing_behavior_contents_filter_name}
          <svg style={{display:isMap? 'none' : 'inline'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="9" y1="3" x2="3" y2="9"></line><line x1="3" y1="3" x2="9" y2="9"></line></svg>
        </div>
      );
    }
    else if (data.purchasing_behavior_item_filter_selected === 2) {
      let brandId = "navi_4_" + data.purchasing_behavior_contents_filter_id;
      let remove = "を除外";
      return (
        <div
          key={`brand_contents_${id}_${data.purchasing_behavior_contents_filter_id}`}
          style={{ cursor:isMap? "default" : "pointer", display: "inline-block" }}
          className="filterRemove"
          onClick={this.deletefilter.bind(this, data.purchasing_behavior_contents_filter_id, id, 4)}
          id={brandId}
        >
          {data.purchasing_behavior_contents_filter_name}{remove}
          <svg style={{display:isMap? 'none' : 'inline'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="9" y1="3" x2="3" y2="9"></line><line x1="3" y1="3" x2="9" y2="9"></line></svg>
        </div>
      );
    }
  }

  /**
   * パラメータ検索結果を作成する(購買行動)
   * @param {object} data - 購買行動項目情報
   * @param {number} id - 購買行動ブロックid
   * @return {JSX} 購買行動ブロック名を表示するHTMLを返す
   */
  brandChildrenGeneratorForSearch = (data, id) => {
    if (data.search_result_hit === 1) {
      let brandId = "navi_4_" + data.purchasing_behavior_contents_filter_id;
      return (
        <label key={`brand_contents_${id}_${data.purchasing_behavior_contents_filter_id}`} style={{display: 'block'}}>
          <input 
            type="checkbox"
            id={data.purchasing_behavior_contents_filter_name}
            onChange={this.addSearch.bind(this, data.purchasing_behavior_contents_filter_id, id, 4)}
            checked={data.purchasing_behavior_item_filter_selected === 1}
          />
          {data.purchasing_behavior_contents_filter_name}
        </label>
      );
    }
  }

  /**
   * 条件一覧モーダル内で項目の削除が行われた場合にそれを絞込条件に反映させる
   * @param {number} deleteId - 項目id
   * @param {number} parentId - ブロックid
   * @param {number} titleId - カテゴリid
   */
  deletefilter(deleteId, parentId, titleId) {
    let del_list = JSON.parse(JSON.stringify(this.state.displayFilterState));
    if (del_list.length !== 0) {
      if (titleId === 1) {//basic
        for (let i = 0; i < del_list.basic_list.length; i++) {
          if (del_list.basic_list[i].basic_id === parentId) {
            if (del_list.basic_list[i].basic_filter_list.length !== 0) {
              for (let j = 0; j < del_list.basic_list[i].basic_filter_list.length; j++) {
                if (del_list.basic_list[i].basic_filter_list[j].basic_filter_id === deleteId) {
                  del_list.basic_list[i].basic_filter_list[j].basic_filter_selected = 0;
                }
                else { continue; }
              }
            }
          }
          else { continue; }
        }
      }
      else if (titleId === 2) {//lifestyle
        for (let i = 0; i < del_list.lifestyle_list.length; i++) {
          if (del_list.lifestyle_list[i].lifestyle_id === parentId) {
            if (del_list.lifestyle_list[i].lifestyle_filter_list.length !== 0) {
              for (let j = 0; j < del_list.lifestyle_list[i].lifestyle_filter_list.length; j++) {
                if (del_list.lifestyle_list[i].lifestyle_filter_list[j].lifestyle_filter_id === deleteId) {
                  del_list.lifestyle_list[i].lifestyle_filter_list[j].lifestyle_filter_selected = 0;
                }
                else { continue; }
              }
            }
          }
          else { continue; }
        }
      }
      else if (titleId === 3) {//persona
        for (let i = 0; i < del_list.persona_list.length; i++) {
          if (del_list.persona_list[i].axis_id === parentId) {
            if (del_list.persona_list[i].persona_filter_list.length !== 0) {
              for (let j = 0; j < del_list.persona_list[i].persona_filter_list.length; j++) {
                if (del_list.persona_list[i].persona_filter_list[j].persona_filter_id === deleteId) {
                  del_list.persona_list[i].persona_filter_list[j].persona_filter_selected = 0;
                }
                else { continue; }
              }
            }
          }
          else { continue; }
        }
      }
      else if (titleId === 4) {//brand
        for (let i = 0; i < del_list.purchasing_behavior_list.length; i++) {
          if (del_list.purchasing_behavior_list[i].purchasing_behavior_id === parentId) {
            if (del_list.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list.length !== 0) {
              for (let j = 0; j < del_list.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list.length; j++) {
                if (parseInt(del_list.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list[j].purchasing_behavior_contents_filter_id) === parseInt(deleteId)) {
                  del_list.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list[j].purchasing_behavior_item_filter_selected = 0;
                }
                else { continue; }
              }
            }
          }
          else { continue; }
        }
      }
    }
    //表示内容をstateに反映
    this.setState({ displayFilterState: del_list });
  }

  /**
   * パラメータ検索モーダル内で項目の追加が行われた場合にそれを絞込条件に反映させる
   * @param {number} addId - 項目id
   * @param {number} parentId - ブロックid
   * @param {number} titleId - カテゴリid
   */
  addSearch(addId, parentId, titleId) {
    let add_list = JSON.parse(JSON.stringify(this.state.displaySearchResult));
    if (add_list.length !== 0) {
      if (titleId === 1) {//basic
        for (let i = 0; i < add_list.basic_list.length; i++) {
          if (add_list.basic_list[i].basic_id === parentId) {
            if (add_list.basic_list[i].basic_filter_list.length !== 0) {
              for (let j = 0; j < add_list.basic_list[i].basic_filter_list.length; j++) {
                if (add_list.basic_list[i].basic_filter_list[j].basic_filter_id === addId) {
                  const reverseSelected = add_list.basic_list[i].basic_filter_list[j].basic_filter_selected === 0? 1 : 0;
                  add_list.basic_list[i].basic_filter_list[j].basic_filter_selected = reverseSelected;
                }
                else { continue; }
              }
            }
          }
          else { continue; }
        }
      }
      else if (titleId === 2) {//lifestyle
        for (let i = 0; i < add_list.lifestyle_list.length; i++) {
          if (add_list.lifestyle_list[i].lifestyle_id === parentId) {
            if (add_list.lifestyle_list[i].lifestyle_filter_list.length !== 0) {
              for (let j = 0; j < add_list.lifestyle_list[i].lifestyle_filter_list.length; j++) {
                if (add_list.lifestyle_list[i].lifestyle_filter_list[j].lifestyle_filter_id === addId) {
                  const reverseSelected = add_list.lifestyle_list[i].lifestyle_filter_list[j].lifestyle_filter_selected === 0? 1 : 0;
                  add_list.lifestyle_list[i].lifestyle_filter_list[j].lifestyle_filter_selected = reverseSelected;
                }
                else { continue; }
              }
            }
          }
          else { continue; }
        }
      }
      else if (titleId === 3) {//persona
        for (let i = 0; i < add_list.persona_list.length; i++) {
          if (add_list.persona_list[i].axis_id === parentId) {
            if (add_list.persona_list[i].persona_filter_list.length !== 0) {
              for (let j = 0; j < add_list.persona_list[i].persona_filter_list.length; j++) {
                if (add_list.persona_list[i].persona_filter_list[j].persona_filter_id === addId) {
                  const reverseSelected = add_list.persona_list[i].persona_filter_list[j].persona_filter_selected === 0? 1 : 0;
                  add_list.persona_list[i].persona_filter_list[j].persona_filter_selected = reverseSelected;
                }
                else { continue; }
              }
            }
          }
          else { continue; }
        }
      }
      else if (titleId === 4) {//brand
        for (let i = 0; i < add_list.purchasing_behavior_list.length; i++) {
          if (add_list.purchasing_behavior_list[i].purchasing_behavior_id === parentId) {
            if (add_list.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list.length !== 0) {
              for (let j = 0; j < add_list.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list.length; j++) {
                if (parseInt(add_list.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list[j].purchasing_behavior_contents_filter_id) === parseInt(addId)) {
                  const reverseSelected = add_list.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list[j].purchasing_behavior_item_filter_selected === 0? 1 : 0;
                  console.log(add_list.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list[j].purchasing_behavior_item_filter_selected)
                  add_list.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list[j].purchasing_behavior_item_filter_selected = reverseSelected;
                }
                else { continue; }
              }
            }
          }
          else { continue; }
        }
      }
    }
    //表示内容をstateに反映
    this.setState({ displaySearchResult: add_list });
  }  

  /**
   * パラメータ検索をする
   * @param {String} searchWord - 検索ワード
   */
  searchParam(searchWord) {
    let all_params = JSON.parse(JSON.stringify(this.state.displayFilterState));
    if(searchWord === ""){
      // 検索ワードが空(=検索のリセット)になったらsearch_result_hitを全部0にする
      if (all_params.length !== 0) {
        //basic
        for (let i = 0; i < all_params.basic_list.length; i++) {
          if (all_params.basic_list[i].basic_filter_list.length !== 0) {
            for (let j = 0; j < all_params.basic_list[i].basic_filter_list.length; j++) {
              all_params.basic_list[i].basic_filter_list[j].search_result_hit = 0;
            }
          }
        }
        //lifestyle
        for (let i = 0; i < all_params.lifestyle_list.length; i++) {
          if (all_params.lifestyle_list[i].lifestyle_filter_list.length !== 0) {
            for (let j = 0; j < all_params.lifestyle_list[i].lifestyle_filter_list.length; j++) {
              all_params.lifestyle_list[i].lifestyle_filter_list[j].search_result_hit = 0;
            }
            }
          }
        }
        //persona
        for (let i = 0; i < all_params.persona_list.length; i++) {
          if (all_params.persona_list[i].persona_filter_list.length !== 0) {
            for (let j = 0; j < all_params.persona_list[i].persona_filter_list.length; j++) {{
              all_params.persona_list[i].persona_filter_list[j].search_result_hit = 0;
            }
          }
        }
        //brand
        for (let i = 0; i < all_params.purchasing_behavior_list.length; i++) {
          if (all_params.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list.length !== 0) {
            for (let j = 0; j < all_params.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list.length; j++) {
              all_params.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list[j].search_result_hit = 0;
            }
          }
        }
      }
    }
    else{
      if (all_params.length !== 0) {
        //basic
        for (let i = 0; i < all_params.basic_list.length; i++) {
          if (all_params.basic_list[i].basic_filter_list.length !== 0) {
            // 中カテゴリ名でも検索する
            // 中カテゴリでヒットしたら、その配下にある項目は全表示する
            if (all_params.basic_list[i].basic_name.match(searchWord)) {
              all_params.basic_list[i].basic_filter_list.forEach(d => {
                d.search_result_hit = 1;
              })
            }
            // 中カテゴリでヒットしなかったらこれまで通りの検索を行う
            else{
              for (let j = 0; j < all_params.basic_list[i].basic_filter_list.length; j++) {
                if (all_params.basic_list[i].basic_filter_list[j].basic_filter_name.match(searchWord)) {
                // 「検索結果にヒットした」と「絞り込み対象である」を区別するために新しく要素を追加
                  all_params.basic_list[i].basic_filter_list[j].search_result_hit = 1;
                }
                else {
                  all_params.basic_list[i].basic_filter_list[j].search_result_hit = 0;
                }
              }
            }
          }
        }
        //lifestyle
          for (let i = 0; i < all_params.lifestyle_list.length; i++) {
            if (all_params.lifestyle_list[i].lifestyle_filter_list.length !== 0) {
              // 中カテゴリ名でも検索する
              // 中カテゴリでヒットしたら、その配下にある項目は全表示する
              if (all_params.lifestyle_list[i].lifestyle_name.match(searchWord)) {
                all_params.lifestyle_list[i].lifestyle_filter_list.forEach(d => {
                  d.search_result_hit = 1;
                })
              }
              else{
                for (let j = 0; j < all_params.lifestyle_list[i].lifestyle_filter_list.length; j++) {
                  if (all_params.lifestyle_list[i].lifestyle_filter_list[j].lifestyle_filter_name.match(searchWord)) {
                  // 「検索結果にヒットした」と「絞り込み対象である」を区別するために新しく要素を追加
                    all_params.lifestyle_list[i].lifestyle_filter_list[j].search_result_hit = 1;
                  }
                  else { 
                    all_params.lifestyle_list[i].lifestyle_filter_list[j].search_result_hit = 0;
                  }
                }
              }
            }
          }
          //persona
          for (let i = 0; i < all_params.persona_list.length; i++) {
            if (all_params.persona_list[i].persona_filter_list.length !== 0) {
              // 中カテゴリ名でも検索する
              // 中カテゴリでヒットしたら、その配下にある項目は全表示する
              if (all_params.persona_list[i].axis_name.match(searchWord)) {
                all_params.persona_list[i].persona_filter_list.forEach(d => {
                  d.search_result_hit = 1;
                })
              }
              else{
                for (let j = 0; j < all_params.persona_list[i].persona_filter_list.length; j++) {
                  if (all_params.persona_list[i].persona_filter_list[j].persona_filter_name.match(searchWord)) {
                  // 「検索結果にヒットした」と「絞り込み対象である」を区別するために新しく要素を追加
                    all_params.persona_list[i].persona_filter_list[j].search_result_hit = 1;
                  }
                  else {
                    all_params.persona_list[i].persona_filter_list[j].search_result_hit = 0;
                  }
                }
              }
            }
          }
          //brand
          for (let i = 0; i < all_params.purchasing_behavior_list.length; i++) {
            if (all_params.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list.length !== 0) {
              // 中カテゴリ名でも検索する
              // 中カテゴリでヒットしたら、その配下にある項目は全表示する
              if (all_params.purchasing_behavior_list[i].purchasing_behavior_name.match(searchWord)) {
                all_params.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list.forEach(d => {
                  d.search_result_hit = 1;
                })
              }
              else{
                for (let j = 0; j < all_params.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list.length; j++) {
                  if (all_params.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list[j].purchasing_behavior_contents_filter_name.match(searchWord)) {
                  // 「検索結果にヒットした」と「絞り込み対象である」を区別するために新しく要素を追加
                    all_params.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list[j].search_result_hit = 1;
                  }
                  else { 
                    all_params.purchasing_behavior_list[i].purchasing_behavior_contents_filter_list[j].search_result_hit = 0;
                  }
                }
              }
            }
          }
        }
      }
      //表示内容をstateに反映
      this.setState({ 
        displaySearchResult: all_params,
      })
    }

  /**
   * 検索ワードを削除する
   */
  deleteValue(){
    this.searchParam.bind(this, "")();
    this.setState({searchWord: ""});
  }

  /**
   * 絞り込み条件を名前を付けて保存する
   * @param {string} hash - #以降のURL
   * @param {string} get - URLから取り出したページid
   * @param {string} label - トップページ保存なのか詳細ページ保存なのか判別するラベル  
   * @param {string} saveName - 保存名
   * @param {string} queryData - 保存データ
   */
  saveData() {
    let hash = decodeURIComponent(window.location.hash);
    let get = hash.split("page=")[1];//URLのpage=からを取り出す
    let self = this;
    let label;
    let saveName = document.getElementById("saveName").value;
    let queryData = JSON.stringify(self.state.displayFilterState);

    //時刻データを取得して変数jikanに格納する
    let jikan = new Date();
    //時・分・秒を取得する
    let day = jikan.getDay();
    let hour = jikan.getHours();
    let minute = jikan.getMinutes();
    let second = jikan.getSeconds();
    if (saveName === "") {
      alert("保存名を入力してください。");
      return false;
    }
    //pageIdから帳票が必要かどうか判定
    // 調査年のラベルを探す
    let surveyLabel;
    let surveys = JSON.parse(localStorage.getItem("sy"));
    if (get !== undefined) {
      if (get.charAt(0) === "1") {
        label = "詳細ページ";
        for(let i = 0; i < surveys.length; i++){
          if(surveys[i].name === formatSurvey()){
            surveyLabel = surveys[i].label;
          }
        }
      }
      else {
        label = "トップページ";
        for(let i = 0; i < surveys.length; i++){
          if(surveys[i].name === formatSurvey()){
            surveyLabel = surveys[i].label;
          }
        }
      }
    }
    else {
      label = "トップページ";
      for(let i = 0; i < surveys.length; i++){
        if(surveys[i].name === formatSurvey()){
          surveyLabel = surveys[i].label;
        }
      }
    }
    new Api().post("/api/v1/saved_queries", {
      page_id: day + hour + minute + second,
      page_label: label,
      name: saveName,
      query: queryData,
      survey: surveyLabel,
      sex: Number(sessionStorage.getItem("se")),
    }, response => {
      self.setState({ data: response.data });
      this.props.setSaveData(response.data);
      alert("「" + saveName + "」を保存しました。");
    });
    this.closeModal.bind(this)();
  }

  /**
   * ページによって画面に表示するボタンの種類を変更する
   * @param {string} pageId - 開いているページid
   * @param {string} trialDisable - トライアルユーザの機能制限
   * @return {JSX} ナビバーに表示するボタンのHTMLを返す
   */
  display() {
    let pageId = window.location.hash.split("page=")[1];
    let trialDisable = (localStorage.getItem("tr") === "true")
    if (pageId === undefined || pageId === null) {
      if (window.location.hash.split("/")[1] === "mypage") {
        return (//マイページ
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="container-fluid" style={{justifyContent: "end"}}>
                <Nav.Item>
                  <Button
                    onMouseDown={this.handleClick.bind(this, "logout")}
                    className="d-block"
                    variant="secondary"
                    id="logout"
                    style={{ display: "block", whiteSpace: "nowrap" }}
                    size="sm"
                  >
                    ログアウト
                    <img src={logout} alt="" width="15" height="15" className="ml-1 mb-1" />
                  </Button>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </>
        );
      }
      else if (this.state.filterState === "") {//トップページ
        return (
          <div className="d-flex justify-content-center mt-4 pt-4"></div>
        );
      }
      else {
        let userAgent = window.navigator.userAgent.toLowerCase();
        return (
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="container-fluid" style={{justifyContent: "end"}}>
                <Nav.Item>
                  <Button
                    onClick={this.openModal.bind(this, "search")}
                    className="d-block mr-2"
                    variant="secondary"
                    id="save"
                    style={{ display: "block", whiteSpace: "nowrap", cursor: userAgent.indexOf('trident') !== -1 ? "not-allowed" : "pointer"}}
                    size="sm"
                    disabled={userAgent.indexOf('trident') !== -1}
                  >
                    項目・パラメータ検索
                    <img src={search} alt="" width="15" height="15" className="ml-1 mb-1" />
                  </Button>
                  <Modal
                    onHide={this.closeModal}
                    size="lg"
                    show={this.state.searchModalIsOpen}
                    centered
                  >
                    <Modal.Header className="filter-save-modal-header" style={{textAlign: "center"}}>
                      <h5 style={{marginBottom: 0}}><b>項目・パラメータ検索</b></h5>
                    </Modal.Header>
                    <Modal.Body className="filter-save-modal-content">
                      <div className="d-flex justify-content-start">
                        <form>
                          <input 
                            type="text" 
                            id="searchParam" 
                            className="saveName" 
                            onChange={e => {
                              this.searchParam.bind(this, e.target.value)();
                              this.setState({ searchWord: e.target.value});
                            }}
                            onKeyPress={e => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                              }
                            }}
                            value={this.state.searchWord} 
                            placeholder="Search..."
                          />
                          <svg xmlns="http://www.w3.org/2000/svg" onClick={this.deleteValue.bind(this)} width="16" height="16" fill="currentColor" className="bi bi-x-circle deleteValue" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                          </svg>
                        </form>
                        {/* <button 
                          className="saveButton" 
                          onClick={this.searchParam.bind(this)} 
                          size="sm"
                        >
                          検索する
                        </button> */}
                      </div>
                      <div className="body mt-2" style={{ overflowY: "scroll", width: "500px", height: "500px" }}>
                        {(() => {
                          if(this.state.displaySearchResult === ""){
                            return false;
                          }
                          else{
                            console.log(this.state.displaySearchResult)
                            const {existBasic, existLifestyle, existPersona, existBrand} = this.checkHit(this.state.displaySearchResult)
                            return (
                              <>
                                <div>
                                  <span style={{fontSize: "1.5rem", fontWeight: "bold", display: existBasic? 'block' : 'none'}}>基本属性</span>
                                  {this.state.displaySearchResult.basic_list.map(d => this.basicStateGenerator(d, false, false))}
                                </div>
                                <div>
                                  <span style={{fontSize: "1.5rem", fontWeight: "bold", display: existLifestyle? 'block' : 'none'}}>価値観</span>
                                  {this.state.displaySearchResult.lifestyle_list.map(d => this.lifestyleStateGenerator(d, false, false))}
                                  </div>
                                <div>
                                  <span style={{fontSize: "1.5rem", fontWeight: "bold", display: existPersona? 'block' : 'none'}}>ペルソナスケルトン</span>
                                  {this.state.displaySearchResult.persona_list.map(d => this.personaStateGenerator(d, false, false))}
                                </div>
                                <div>
                                  <span style={{fontSize: "1.5rem", fontWeight: "bold", display: existBrand? 'block' : 'none'}}>ブランド選択</span>
                                  {this.state.displaySearchResult.purchasing_behavior_list.map(d => this.brandStateGenerator(d, false, false))}
                                </div>
                              </>
                            )
                          }
                        })()}
                      </div>
                      <div className="d-flex mt-2 pr-4" style={{justifyContent: "end"}}>
                        <Button
                          onClick={this.reFilter.bind(this, "search")}
                          className="mx-2"
                          size="sm"
                          variant="secondary"
                        >
                          この条件で表示する
                        </Button>
                        <Button onClick={this.closeModal} size="sm" variant="secondary">
                          閉じる
                        </Button>
                      </div>
                    </Modal.Body>
                  </Modal>
                </Nav.Item>
                <Nav.Item>
                  <Button
                    onClick={this.openModal.bind(this, "save")}
                    className="d-block mr-2"
                    variant="secondary"
                    id="save"
                    style={{ display: "block", whiteSpace: "nowrap" }}
                    size="sm"
                  >
                    絞り込み条件保存
                    <img src={save} alt="" width="15" height="15" className="ml-1 mb-1" />
                  </Button>
                  <Modal
                    onHide={this.closeModal}
                    size="lg"
                    show={this.state.saveModalIsOpen}
                    centered
                  >
                    <Modal.Header className="filter-save-modal-header" style={{textAlign: "center"}}>
                      <h5 style={{marginBottom: 0}}><b>絞り込み条件</b></h5>
                    </Modal.Header>
                    <Modal.Body className="filter-save-modal-content">
                      <div className="body mt-2" style={{ overflowY: "scroll", width: "700px", height: "500px" }}>
                        <div>{this.state.displayFilterState.basic_list.map(d => this.basicStateGenerator(d, true, false))}</div>
                        <div>{this.state.displayFilterState.lifestyle_list.map(d => this.lifestyleStateGenerator(d, true, false))}</div>
                        <div>{this.state.displayFilterState.persona_list.map(d => this.personaStateGenerator(d, true, false))}</div>
                        <div>{this.state.displayFilterState.purchasing_behavior_list.map(d => this.brandStateGenerator(d, true, false))}</div>
                      </div>
                      <div className="d-flex mt-2">
                        <input 
                          type="textbox" 
                          id="saveName" 
                          className="saveName" 
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                            }
                          }}
                        />
                        <button 
                          className="saveButton" 
                          onClick={this.saveData.bind(this)} 
                          size="sm"
                        >
                          保存する
                        </button>
                        <Button
                          onClick={this.reFilter.bind(this, "filter")}
                          className="mx-2"
                          size="sm"
                          variant="secondary"
                        >
                          この条件で表示する
                      </Button>
                        <Button onClick={this.closeModal} size="sm" variant="secondary">
                          閉じる
                      </Button>
                      </div>
                    </Modal.Body>
                  </Modal>
                </Nav.Item>
                <Nav.Item>
                  <Button
                    onClick={window.print}
                    className="d-block mr-2"
                    variant="secondary"
                    id="print"
                    style={{ display: "block", whiteSpace: "nowrap" }}
                    size="sm"
                    disabled={trialDisable}
                  >
                    プリント
                    <img src={printer} alt="" width="15" height="15" className="ml-1 mb-1" />
                  </Button>
                </Nav.Item>
                <Nav.Item>
                  <Button
                    onClick={this.forceUpdatePage.bind(this)}
                    onMouseDown={this.handleClick.bind(this, "mypage")}
                    className="d-block mr-2"
                    variant="secondary"
                    id="mypage"
                    style={{ display: "block", whiteSpace: "nowrap" }}
                    size="sm"
                  >
                    マイページ
                    <img src={mypage} alt="" width="15" height="15" className="ml-1 mb-1" />
                  </Button>
                </Nav.Item>
                <Nav.Item>
                  <Button
                    onMouseDown={this.handleClick.bind(this, "logout")}
                    className="d-block mr-2"
                    variant="secondary"
                    id="logout"
                    style={{ display: "block", whiteSpace: "nowrap" }}
                    size="sm"
                  >
                    ログアウト
                    <img src={logout} alt="" width="15" height="15" className="ml-1 mb-1" />
                  </Button>
                </Nav.Item>
                <Nav.Item>
                  <img
                    width="25px"
                    height="25px"
                    src={question}
                    onClick={this.openHelp.bind(this)}
                    style={{ cursor: "pointer", display: "block" }}
                    id="help"
                    alt=""
                  />
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </>
        );
      }
    }
    else {
      let pageNumber = pageId.slice(0, 1);
      if (pageNumber === "1") {//ペルソナ詳細ページ
        if (this.state.filterPersonaState === "") {
          return (
            <div className="d-flex justify-content-center mt-4 pt-4"></div>
          );
        }
        else {
          return (
            <>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="container-fluid" style={{justifyContent: "end"}}>
                  <Nav.Item>
                    <Button
                      onClick={this.openModal.bind(this, "save")}
                      className="d-block mr-2"
                      variant="secondary"
                      id="save"
                      style={{ display: "block", whiteSpace: "nowrap" }}
                      size="sm"
                    >
                      絞り込み条件保存
                      <img src={save} alt="" width="15" height="15" className="ml-1 mb-1" />
                    </Button>
                    <Modal
                      onHide={this.closeModal}
                      size="lg"
                      show={this.state.saveModalIsOpen}
                      centered
                    >
                    <Modal.Header className="filter-save-modal-header" style={{textAlign: "center"}}>
                      <h5 style={{marginBottom: 0}}><b>絞り込み条件</b></h5>
                    </Modal.Header>
                    <Modal.Body className="filter-save-modal-content">
                      <div className="body mt-2" style={{ overflowY: "scroll", width: "700px", height: "500px" }}>
                        <div>{this.state.displayFilterState.basic_list.map(d => this.basicStateGenerator(d, true, false))}</div>
                        <div>{this.state.displayFilterState.lifestyle_list.map(d => this.lifestyleStateGenerator(d, true, false))}</div>
                        <div>{this.state.displayFilterState.persona_list.map(d => this.personaStateGenerator(d, true, false))}</div>
                        <div>{this.state.displayFilterState.purchasing_behavior_list.map(d => this.brandStateGenerator(d, true, false))}</div>
                      </div>
                      <div className="d-flex mt-2">
                        <input 
                          type="textbox" 
                          id="saveName" 
                          className="saveName" 
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                            }
                          }}
                        />
                        <button 
                          className="saveButton" 
                          onClick={this.saveData.bind(this)} 
                          size="sm"
                        >
                          保存する
                        </button>
                        <Button 
                          onClick={this.reFilter.bind(this, "filter")} 
                          size="sm"  
                          className="mx-2" 
                          variant="secondary"
                        >
                          この条件で表示する
                        </Button>
                        <Button onClick={this.closeModal} size="sm" variant="secondary">
                          閉じる
                        </Button>
                      </div>
                      </Modal.Body>
                    </Modal>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      onClick={window.print}
                      className="d-block mr-2"
                      variant="secondary"
                      id="print"
                      style={{ display: "block", whiteSpace: "nowrap" }}
                      size="sm"
                      disabled={trialDisable}
                    >
                      プリント
                      <img src={printer} alt="" width="15" height="15" className="ml-1 mb-1" />
                    </Button>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      onMouseDown={this.handleClick.bind(this, "logout")}
                      className="d-block mr-2"
                      variant="secondary"
                      id="logout"
                      style={{ display: "block", whiteSpace: "nowrap" }}
                      size="sm"
                    >
                      ログアウト
                      <img src={logout} alt="" width="15" height="15" className="ml-1 mb-1" />
                    </Button>
                  </Nav.Item>
                  <Nav.Item>
                    <img
                      width="25px"
                      height="25px"
                      src={question}
                      onClick={this.openHelp.bind(this)}
                      style={{ cursor: "pointer", display: "block" }}
                      id="help"
                      alt=""
                    />
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </>
          );
        }
      }
      else if (pageNumber === "2") {//マップページ
        if (this.state.filterState === "") {
          return (
            <div className="d-flex justify-content-center mt-4 pt-4"></div>
          );
        }
        else {
          return (
            <>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="container-fluid" style={{justifyContent: "end"}}>
                  <Nav.Item>
                    <Button
                      onClick={this.openModal.bind(this, "save")}
                      className="d-block mr-2"
                      variant="secondary"
                      id="save"
                      style={{ display: "block", whiteSpace: "nowrap" }}
                      size="sm"
                    >
                      絞り込み条件一覧
                      <img src={save} alt="" width="15" height="15" className="ml-1 mb-1" />
                    </Button>
                    <Modal
                      onHide={this.closeModal}
                      size="lg"
                      show={this.state.saveModalIsOpen}
                      centered
                    >
                      <Modal.Header className="filter-save-modal-header" style={{textAlign: "center"}}>
                        <h5 style={{marginBottom: 0}}><b>絞り込み条件</b></h5>
                      </Modal.Header>
                      <Modal.Body className="filter-save-modal-content">
                        <div className="body mt-2" style={{ overflowY: "scroll", width: "700px", height: "500px" }}>
                          <div>{this.state.displayFilterState.basic_list.map(d => this.basicStateGenerator(d, false, true))}</div>
                          <div>{this.state.displayFilterState.lifestyle_list.map(d => this.lifestyleStateGenerator(d, false, true))}</div>
                          <div>{this.state.displayFilterState.persona_list.map(d => this.personaStateGenerator(d, false, true))}</div>
                          <div>{this.state.displayFilterState.purchasing_behavior_list.map(d => this.brandStateGenerator(d, false, true))}</div>
                        </div>
                        <div className="d-flex" style={{justifyContent: "center"}}>
                          <Button onClick={this.closeModal} size="sm" variant="secondary">
                            閉じる
                          </Button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      onMouseDown={this.handleClick.bind(this, "logout")}
                      className="d-block mr-2"
                      variant="secondary"
                      id="logout"
                      style={{ display: "block", whiteSpace: "nowrap" }}
                      size="sm"
                    >
                      ログアウト
                      <img src={logout} alt="" width="15" height="15" className="ml-1 mb-1" />
                    </Button>
                  </Nav.Item>
                  <Nav.Item>
                    <img
                      width="25px"
                      height="25px"
                      src={question}
                      onClick={this.openHelp.bind(this)}
                      style={{ cursor: "pointer", display: "block" }}
                      id="help"
                      alt=""
                    />
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </>
          );
        }
      }
    }
  }

  /**
   * 実際にウィンドウに表示する
   * @return {JSX} 各種ボタン表示用HTMLを返す
   */
  render() {
    // 年度切替が行われた(localStorageが変わった)か確認する
    // 切り替わったなら、パラメータ検索の中身を空にする
    const now = localStorage.getItem("sv");
    if(this.state.survey === "" || this.state.survey !== now){
      this.deleteValue.bind(this);
      this.setState({survey: now});
    }
    return (
      <Navbar
        variant="light"
        style={{ marginRight: 3, verticalAlign: "center", justifyContent: "space-between" }}
      >
        <Navbar.Brand onClick={this.handleClick.bind(this, "top")}>
          <img src={logo} alt="logo" height="100px" style={{ cursor: "pointer", textAlign: "center" }} /><br />
        </Navbar.Brand>
        {this.display()}
      </Navbar>
    );
  }
}
export default Navi;