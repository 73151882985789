
import axiosBase from 'axios';
/**
 * API通信処理
 *
 * @export
 * @class Api
 */
export default class Api {
    axios;

    /**
     *Creates an instance of Api.
     * @memberof Api
     */
    constructor() {
        this.axios = axiosBase.create({
            baseURL: process.env.REACT_APP_API_ORIGIN,
            timeout: 129000,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `bearer ${localStorage.getItem("it")}`
            }
        });
        // 時間計測
        this.axios.interceptors.request.use(config => {
            config.ts = Date.now();
            return config
        });
    }

    /**
     * HTTP GET通信　
     * 
     * @param {*} path  - エンドポイント
     * @param {*} successed  - 成功時処理
     * @param {*} [errored=() => {}] - エラー時処理
     * @param {*} [always=() => {}] -  終了後処理
     * @returns
     * @memberof Api
     */
    get(path, successed, errored = () => { }, always = () => { }) {
        // タイムアウト検知
        const lt = localStorage.getItem("lt");
        const rt = localStorage.getItem("rt");
        if (typeof (lt) === "undefined" || typeof (rt) === "undefined" ) {
            alert("エラーが発生しました。ログイン画面に戻ります。\n操作途中のデータは保存されません。");
            this.clearLocalStorage();
            window.location.href = "./#/login";
            return;
        } else if (new Date().getTime() - lt > 1000 * 60 * 60) {
            alert("一定時間操作がありませんでした。ログイン画面に戻ります。\n操作途中のデータは保存されません。");
            this.clearLocalStorage();
            window.location.href = "./#/login";
            return;
        }
        return this.axios.get(path).then(result => {
            console.log(`GET ${path} OK (${Date.now()} ms)`);
            // console.log(`GET ${path} OK (${Date.now() - result.config.ts} ms)`);
            localStorage.setItem("lt", new Date().getTime());
            successed(result);
        }).catch(error => {
            console.log(`GET ${path} ERROR (${Date.now()} ms)`);
            // console.log(`GET ${path} ERROR (${Date.now() - error.config.ts} ms)`);
            console.log(`ERROR_CODE ${error.code}`);
            this.clearLocalStorage();
            errored(error);
        }).then(always())
    }

    /**
     * HTTP POST通信
     *
     * @param {*} path - エンドポイント
     * @param {*} param - リクエストパラメータ
     * @param {*} successed -  成功時処理
     * @param {number} [retry=5] - リトライ回数
     * @param {*} [errored=() => {}] - エラー時処理
     * @param {*} [always=() => {}] - 終了時処理
     * @returns
     * @memberof Api
     */
    post(path, param, successed, retry = 5, errored = () => { }, always = () => { }) {
        // タイムアウト検知
        const lt = localStorage.getItem("lt");
        const rt = localStorage.getItem("rt");
        if (typeof (lt) === "undefined" || typeof (rt) === "undefined" ) {
            alert("エラーが発生しました。ログイン画面に戻ります。\n操作途中のデータは保存されません。");
            this.clearLocalStorage();
            window.location.href = "./#/login";
            return;
        } else if (new Date().getTime() - lt > 1000 * 60 * 60) {
            alert("一定時間操作がありませんでした。ログイン画面に戻ります。\n操作途中のデータは保存されません。");
            this.clearLocalStorage();
            window.location.href = "./#/login";
            return;
        }
        return this.axios.post(path, param)
            .then(result => {
                console.log(`POST ${path} OK (${Date.now() - result.config.ts} ms)`);
                localStorage.setItem("lt", new Date().getTime());
                successed(result);

            }).catch(error => {
                // console.log(`POST ${path} ERROR (${Date.now() - error.config.ts} ms)`);
                console.log(`ERROR_CODE ${error.code}`);
                // console.log(`ERROR_MSG ${error.message}`);

                // タイムアウト時処理
                // if (error.code === "ECONNABORTED") {
                if (!error.response) {
                        console.log(`NETWORK ERROR. RETRY_COUNT: ${retry}`);
                    if (retry > 0) {
                        this.post(path, param, successed, retry - 1, () => { }, () => { });
                    }
                    // 認証エラー
                } else if (error.response.status === 401) {
                    // トークン更新
                    console.log("trying refresh token");
                    this.axios.post('/api/v1/login',
                        {
                            refresh: localStorage.getItem("rt"),

                        }).then(data => {
                            if (data.data.AuthenticationResult) {
                                const idToken = data.data.AuthenticationResult.IdToken;
                                const accessToken = data.data.AuthenticationResult.AccessToken;
                                localStorage.setItem("it", idToken);
                                localStorage.setItem("at", accessToken);
                                new Api().post(path, param, successed, retry, errored, always);
                            }
                            // トークン更新失敗
                        }).catch(error => {
                            alert("認証に失敗しました。ログイン画面に戻ります。\n操作途中のデータは保存されません。");
                            this.clearLocalStorage();
                            window.location.href = "./#/login";
                        });
                    // 端末の競合
                } else if (error.response.status === 409) {
                    alert("別端末からのログインを検出しました。ログイン画面に戻ります。\n操作途中のデータは保存されません。");
                    this.clearLocalStorage();
                    window.location.href = "./#/login";
                    // サーバエラー
                } else if (error.response.status === 500) {
                    console.log(`SERVER_ERROR. RETRY_COUNT: ${retry}`);
                    if (retry > 0) {
                        setTimeout(this.post(path, param, successed, retry - 1, () => { }, () => { }), 1000);
                    } else {
                        console.log("retry limit exceeded.")
                        console.log(error);
                        this.clearLocalStorage();
                    }
                } else {
                    console.log(error);
                    this.clearLocalStorage();
                }
                errored(error);
            }).then(always());
    }

    clearLocalStorage() {
        localStorage.removeItem("ci");
        localStorage.removeItem("tr");
        localStorage.removeItem("it");
        localStorage.removeItem("rt");
        localStorage.removeItem("sy");
        localStorage.removeItem("ss");
        localStorage.removeItem("sv");
        sessionStorage.removeItem("ss");
        localStorage.removeItem("se");
        sessionStorage.removeItem("se");
    }


}